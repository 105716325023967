import { DownloadOutlined } from '@ant-design/icons'
import { Button, Col, message, Row, Skeleton } from 'antd'
import { swalError } from 'components/SwalError/SwalError'
import dayjs from 'dayjs'
import { IDocumentalReport, IDynamicProposalStep, IDynamicProposalUser, IEmailAgeRequest, IRodobensKYCProcess, IRodobensKycReponse, IRodobensKYCStatus, IUnicoRequest, _rodobensKYCProcessType } from 'ecp/app/Proposals/proposalInterfaces'
import ProposalModel from 'ecp/models/ProposalModel'
import { _userLevel } from 'ecp/models/UsersModel'
import DynamicProposalECPFRepository from 'ecpf/repositories/DynamicProposalECPFRepository'
import RodobensKYCRepository from 'ecpf/repositories/RodobensKYCRepository'
import React, { useEffect, useState } from 'react'
import { renderBadge } from 'utils/renderBadge'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import { TRANSLATE_STATUS } from '../../proposalConstants'
import { generateDocumentalReport } from '../../proposalFunctions'
import { ProposalStepContent } from '../ProposalSteps/ProposalSteps'
import { ResendDocumental } from '../ResendDocumental/ResendDocumental'

function ShowValues ({ loading, item }: {loading: boolean, item: IRodobensKYCProcess}) {
  if (loading) return <Skeleton.Input className='w-50' active={true} />
  const description = item?.status?.description || 'Pendente'
  return <span className={ProposalModel.returnCorrectEmailAgeCLass(item?.solution, item?.status?.score ?? 0, item)}>
    { item?.status?.score
      ? `${item.status.score} - ${description}`
      : description
    }
  </span>
}

interface IGenerateReportProps {
  operation: {
    releasedAmount?: number
    financedAmount?: number
    product: string
  }
  client: IDynamicProposalUser & { address: string }
}

interface IDocumentalProcessProps {
  resendUnicoAcertPixInfo?: IUnicoRequest
  resendEmailAgeInfo?: IEmailAgeRequest
  generateReportInfo?: IGenerateReportProps
  step: IDynamicProposalStep
  proposalId?: string
}

export function DocumentalProcess ({ resendUnicoAcertPixInfo, resendEmailAgeInfo, generateReportInfo, step, proposalId }: IDocumentalProcessProps) {
  const initialProcess: IRodobensKYCProcess[] = [
    {
      solution: 'Único',
      typeDescription: 'Reconhecimento Facial',
      type: 'status',
      status: {},
      responseDate: '-'
    },
    {
      solution: 'Único',
      typeDescription: 'Prova de vida',
      type: 'liveness',
      status: {},
      responseDate: '-'
    },
    {
      solution: 'Único',
      typeDescription: 'Facematch',
      type: 'facematch',
      status: {},
      responseDate: '-'
    },
    {
      solution: 'AcertPix GED',
      typeDescription: 'Reconhecimento de RG/CNH',
      type: 'acertPix',
      status: {},
      responseDate: '-'
    },
    {
      solution: 'Email Age',
      typeDescription: 'Validação de Email',
      type: 'fraudRisk',
      status: {},
      responseDate: '-'
    },
    {
      solution: 'Email Age',
      typeDescription: 'Validação de dados complementares',
      type: 'overallDigitalIdentityScore',
      status: {},
      responseDate: '-'
    }
  ]

  const [loadingReport, setLoadingReport] = useState(false)
  const [loadingStatusColumn, setLoadingStatusColumn] = useState<boolean>(false)
  const [process, setProcess] = useState<IRodobensKYCProcess[] | undefined >(initialProcess)

  const addResponseDate = (status: Record<string, IRodobensKYCProcess['status']>) => {
    const hasResponseDate = status?.responseDate
    if (!hasResponseDate) return status

    const makeObj: { [key: string]: any } = {}
    const statusEntries = Object.entries(status)

    for (const [key, value] of statusEntries) {
      makeObj[key] = { ...value, responseDate: status?.responseDate }
    }

    return makeObj
  }

  const flattenProcesses = (statusData: IRodobensKycReponse) => {
    const flattenedData = Object.assign(
      {},
      addResponseDate(statusData.emailAge),
      addResponseDate(statusData.unico),
      addResponseDate(statusData.acertPix)
    )

    const updatedIncialProcess = initialProcess.map((item) => {
      const key = item.type

      if (flattenedData[key]) {
        item.status = flattenedData[key]
      }

      return item
    })

    return updatedIncialProcess
  }

  const fetchDocumentalProcess = async () => {
    try {
      setLoadingStatusColumn(true)
      if (!proposalId) throw new Error('Falha ao buscar proposta.')

      const response = await RodobensKYCRepository.getDocumentalStep(proposalId)
      const statusData = response.data.data.status

      const initialProcessData = flattenProcesses(statusData)
      setProcess(initialProcessData)
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoadingStatusColumn(false)
    }
  }

  const formatDate = (date?: string): string => {
    if (!date) return '-'
    if (!dayjs(date).isValid()) return '-'

    return timeAsDayjs(date, { applyTimezone: false }).format('DD/MM/YYYY HH:mm:ss')
  }

  const fetchStepHistories = async (departmentId: string, stepId: string) => {
    const abortController = new AbortController()

    try {
      if (!proposalId) throw new Error('Não foi possível obter o ID da proposta')

      const response = await DynamicProposalECPFRepository.fetchStepHistory(proposalId, departmentId, stepId)
      const { history } = response.data?.data || {}

      if (!history) throw new Error('Não foi possível obter o histórico do passo da proposta')

      const userPlusLevel = (user?: { code: string, level: _userLevel, name: string }): string | null => {
        const { level, name } = user || {}

        if (!name && !level) return null
        if (name && !level) return name

        return `${name} (${translate.level(level as string)})`
      }

      const formatedHIstories = history.map(item => {
        const { user, label } = item?.currentStep || {}
        const userName = userPlusLevel(user)

        return {
          name: `${userName || 'Não autenticado'} - ${formatDate(item?.createdAt)}`,
          label: TRANSLATE_STATUS?.[label as keyof typeof TRANSLATE_STATUS]
        }
      })

      return formatedHIstories
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (err?.message) message.error(err.message)
      }
      setLoadingReport(false)
    }
  }

  const formatStepHistories = (stepsHistory: IRodobensKycReponse[]) => {
    return stepsHistory.map(step => {
      const flatten = flattenProcesses(step)

      return (flatten || []).map(process => {
        const { solution, typeDescription, status: itemStatus } = process

        const status = `${itemStatus?.score ?? ''} - ${itemStatus?.description ?? ''}`
        const validated = !!Object.values(process?.status || {}).toString()

        return {
          solution,
          type: typeDescription as _rodobensKYCProcessType,
          status: status as IRodobensKYCStatus,
          validated,
          responseDate: itemStatus?.responseDate ? timeAsDayjs(itemStatus?.responseDate, { applyTimezone: false }).format('DD/MM/YYYY HH:mm:ss') : '-'
        }
      })
    })
  }

  const onGenerateReport = async (data: IGenerateReportProps) => {
    const { operation, client } = data
    if (!data || !proposalId) return swalError({ err: 'Não foi possível obter os dados da proposta' })
    setLoadingReport(true)

    const comments = await fetchStepHistories(step.departmentId, step._id)

    const responseHistory = await RodobensKYCRepository.getDocumentalHistory(proposalId)
    const stepsHistory = responseHistory.data.data?.data.history || []

    const formattedStepsHistories = formatStepHistories(stepsHistory)

    const reportData: IDocumentalReport = {
      client,
      operation,
      steps: formattedStepsHistories || [],
      comment: '',
      comments: comments || []
    }

    const pdfUrl = await generateDocumentalReport(reportData)
    setLoadingReport(false)

    const a = document.createElement('a')
    a.href = pdfUrl
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    return document.body.removeChild(a)
  }

  useEffect(() => {
    fetchDocumentalProcess()
  }, [])

  return (
    <div>
      <div className='documental-process__title-container'>
        <div>
          <ProposalStepContent.Title>
            Processos de análise documental
          </ProposalStepContent.Title>

          <ProposalStepContent.Description className="documental-process__description">
            Confira o status de cada processo de análise documental
          </ProposalStepContent.Description>
        </div>

        <Button
          type='primary'
          onClick={() => {
            generateReportInfo && onGenerateReport(generateReportInfo)
          }}
          loading={loadingReport}
          disabled={loadingStatusColumn}
          className="documental-process__reports-button"
        >
          <DownloadOutlined /> Baixar relatório
        </Button>
      </div>

      <div className="documental-process__process-container">
        <Row className="documental-process__process documental-process__process-header">
          <Col className="text-center">Solução</Col>
          <Col>Tipo</Col>
          <Col className="text-center">Status</Col>
        </Row>

        {(process || [])?.map((item: IRodobensKYCProcess, idx) => {
          return (
            <Row key={idx} className="documental-process__process">
              <Col className="text-center">
                {renderBadge(item.solution, 'rodobensKYC')}
              </Col>

              <Col>
                {item.typeDescription}
              </Col>

              <Col className="text-center">
                <ShowValues loading={loadingStatusColumn} item={item}/>
              </Col>
            </Row>
          )
        })}
      </div>

      {step?.canResendUnicoAndEmailAgeLink &&
        <ResendDocumental
          resendEmailAgeInfo={resendEmailAgeInfo}
          resendUnicoAcertPixInfo={resendUnicoAcertPixInfo}
          fetchDocumentalProcess={fetchDocumentalProcess}
          proposalId={proposalId}
        />
      }
    </div>
  )
}
