import dayjs from 'dayjs'
import { R$ } from 'ecp/app/Proposals/proposalFunctions'
import { _userLevel } from 'ecp/models/UsersModel'
import { IMergedDueAndDebitBalanceECPF } from 'ecpf/repositories/OperationsECPFRepository'
import { IProposalDetailsECPF, IAnticipationSimulationPayload, IGenerateTicketPayload } from 'ecpf/repositories/ProposalECPFRepository'

const ProposalDuesEcpfModel = {

  // Código enviado pela Rodobens
  // Aparentemete o código do produto no caso de simulação de antecipação de parcelas
  // não é igual ao código do produto da proposta
  // REF: https://agxsoftware.atlassian.net/browse/RODO-1825?focusedCommentId=14344
  productCode: '000002',

  // sempre '000' - Rodobens passou
  cobradoraCode: '000',

  sortSelectedDuesByNumeroSaldo: (selectedDues: IMergedDueAndDebitBalanceECPF[] = []): IMergedDueAndDebitBalanceECPF[] => {
    if (!selectedDues.length) return []
    return selectedDues.sort((a, b) => (a.numeroSaldo ?? 0) - (b.numeroSaldo ?? 0))
  },

  formatAnticipationSimulationPayload: (proposal: IProposalDetailsECPF, selectedDues: IMergedDueAndDebitBalanceECPF[]): IAnticipationSimulationPayload => {
    const parcelas = selectedDues.map(due => ({
      numeroParcela: due.numeroSaldo?.toString() || ''
    }))

    const despesas = {
      valorDespesaReembolsavel: 0,
      valorTarifaHonorario: 0
    }

    const operacoesAcordadas = [
      {
        codigoBackOffice: '01', // sempre 01
        numeroOperacao: proposal?.funcao?.numeroOperacao,
        parcelas
      }
    ]

    const firstDueDateExpire = selectedDues[0].dataVencimento

    const dataBase = dayjs(firstDueDateExpire).isBefore(dayjs())
      ? firstDueDateExpire
      : dayjs().format('YYYY-MM-DD')

    return {
      codigoProduto: ProposalDuesEcpfModel.productCode,
      valorProposto: ProposalDuesEcpfModel.calculateTotalParcelValue(selectedDues),
      dataBase: dataBase,
      quantidadeParcelas: selectedDues?.length || 0,
      dataPrimeiroVencimento: firstDueDateExpire,
      despesas,
      operacoesAcordadas,
      codigoCobradora: ProposalDuesEcpfModel.cobradoraCode
    }
  },

  formaterGenerateTicketPayload: (proposal: IProposalDetailsECPF, selectedDues: IMergedDueAndDebitBalanceECPF[]): IGenerateTicketPayload => {
    const parcelas = selectedDues.map(due => ({
      numeroParcela: due.numeroSaldo?.toString() || ''
    }))

    return {
      numeroOperacao: proposal?.funcao?.numeroOperacao,
      codigoInstalacao: Number(selectedDues[0]?.codigoInstalacao),
      produtoAcordo: ProposalDuesEcpfModel.productCode,
      parcelas,
      dataVencimento: selectedDues[0].dataVencimento,
      quantidadeParcelas: selectedDues?.length,
      gerarBoleto: 'Sim',
      codigoCobradora: ProposalDuesEcpfModel.cobradoraCode
    }
  },

  calculateTotalParcelValue: (selectedDues: IMergedDueAndDebitBalanceECPF[]): number => {
    return selectedDues.reduce((total, due) => total + (due.valorDaParcela ?? 0), 0)
  },

  hasAcctionInDebitBalance: (userLevel: _userLevel): boolean => {
    return ['master', 'client'].includes(userLevel)
  },

  formatR$: (value?: number): string => {
    return value !== undefined ? R$(value) : '-'
  },

  downloadBase64File: (base64: string, fileName: string): void => {
    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${base64}`
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },

  removeZeros: (value: string): string => {
    if (!value) return '-'
    return value.replace(/^0+/, '')
  }

}

export default ProposalDuesEcpfModel
