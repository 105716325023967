import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Layout } from 'antd'
import { Footer, Navbar, ReduxModal, Sidebar } from 'components'
import { CommunicationUnicoModal } from 'components/CommunicationUnicoModal/CommunicationUnicoModal'
import ContactToSupport from 'components/ContactToSupport/ContactToSupport'
import ErrorBoundary from 'components/ErrorBoundaries/ErrorBoundaries'
import ErrorFallback from 'components/ErrorFallback/ErrorFallback'
import Loading from 'components/Loading/Loading'
import MessageLittlePanel from 'components/MessageLittlePanel/MessageLittlePanel'
import ReduxDrawer from 'components/ReduxDrawer/ReduxDrawer'
import { IRoute, RouteGroup, RouteType } from 'components/Sidebar/types'
import ProgressQueueContainer from 'ecp/components/ProgressQueue/ProgressQueueContainer'
import ProductModel, { IProduct, _productSlugs } from 'ecp/models/ProductModel'
import { UsersModel } from 'ecp/models/UsersModel'
import { useAuth, useClient, useProposal, useResources, useSelectedProduct, useSystem } from 'hooks'
import useAuthLayout from 'hooks/useAuthLayout'
import useProgressQueue from 'hooks/useProgressQueue'
import FullScreenError from 'layouts/FullScreenError/FullScreenError'
import FullScreenLGPD from 'layouts/FullScreenLGPD/FullScreenLGPD'
import FullScreenLoading from 'layouts/FullScreenLoading/FullScreenLoading'
import React, { ReactNode, Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { match, matchPath, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { getRoutes } from 'routes'
import { authActions } from 'store/modules/auth/authActions'
import { setRouteConfig } from 'store/modules/system/actions'
import imagesUrls from 'utils/imagesUrls'
import { SocketSingleton } from 'utils/socket'

export const AuthTitle = ({ children, className }: { children: ReactNode, className?: string }) => {
  return <h1 id="auth-page-title" className={`auth-layout__header-title ${className ?? ''}`}>{children}</h1>
}

function Auth () {
  const [routes, setRoutes] = useState<RouteType[]>([])

  const auth = useAuth()
  const client = useClient()
  const system = useSystem()
  const proposal = useProposal()
  const location = useLocation()
  const pageHistory = useHistory()
  const resources = useResources()
  const dispatch = useDispatch()

  const { progressQueue } = useProgressQueue()
  const { layout, handleReset, handleError, handleLoading, handleFetchUserInfo } = useAuthLayout()
  const product = useSelectedProduct()

  const { selectedProduct } = auth

  useLayoutEffect(() => {
    const socket = new SocketSingleton()
    socket.connect()
  }, [])

  const closeFullScreenError = () => {
    handleError('')
  }

  useLayoutEffect(() => {
    if (auth.level && auth.selectedProduct?._id) {
      const baseRoutes = getRoutes(auth.selectedProduct.slug, auth)
      setRoutes(baseRoutes)

      if (ProductModel.isEcpProduct(product)) {
        handleFetchUserInfo(auth.level)
      }
    }
  }, [
    auth.level,
    auth.selectedProduct?._id,
    auth.cpf
  ])

  useEffect(() => {
    const listRoutes = []
    if (routes.length > 0) {
      const typeRoutes: any[] = routes
      for (const item of typeRoutes) {
        if (item.routes) {
          for (const nestedItem of item.routes) {
            listRoutes.push(nestedItem)
          }

          continue
        }

        listRoutes.push(item)
      }
    }

    const routeMatch = (match: string, patch: string): match<{}> | null => matchPath(match, {
      path: patch,
      exact: true,
      strict: false
    })

    const pathname = location.pathname.replace('/auth', '')
    const fetchRoute = listRoutes.find(item => {
      return routeMatch(pathname, item.path)
    })

    const mappedListRoutesToRouterDom = (route: IRoute) => ({
      name: route.name,
      path: route.path
    })

    dispatch(authActions.setUserRouterDomPaths(listRoutes.map(mappedListRoutesToRouterDom)))

    const isTable = (document.querySelector('#server-table') || document.querySelector('#server-table-reset'))
    if (isTable) Object.assign(fetchRoute, { isTable: true })

    if (fetchRoute) dispatch(setRouteConfig({ route: fetchRoute }))
    const isAuth = location.pathname.endsWith('/auth') || location.pathname.endsWith('/auth/')

    if (routes.length > 0 && isAuth) {
      const firstRoute = routes[0] as IRoute
      if (firstRoute.path) pageHistory.push('/auth' + firstRoute.path)
    }
  }, [
    location.pathname,
    routes.length
  ])

  const renderRoutes = (route: IRoute | RouteGroup): ReactNode => {
    if ((route as IRoute).path) {
      const routeItem = route as IRoute

      return (
        <Route
          path={'/auth' + routeItem.path}
          key={routeItem.path}
          exact
        >
          <div className="flex-vertical-center" id="auth-page-header">
            {routeItem.meta.canGoBack && (
              <ArrowLeftOutlined
                className="ant-page-header-back-button pr-2 mb-3"
                onClick={() => history.back()}
              />
            )}

            {!routeItem?.meta?.hiddenTitle &&
              <AuthTitle>
                {system.route?.name}
                {(routeItem.meta.canGoBack && client.name) ? ': ' + client.name : ''}
                {(routeItem.meta.canGoBack && proposal.number) ? ' - ' + proposal.number : ''}
              </AuthTitle>
            }
          </div>

          {routeItem.component ? (
            <ErrorBoundary fallback={<ErrorFallback />}>
              <Suspense fallback={<Loading/>}>
                <routeItem.component />
              </Suspense >
            </ErrorBoundary>
          ) : (
            null
          )}
        </Route>
      )
    } else {
      const routeGroup = (route as RouteGroup)
      return routeGroup.routes.map(route => renderRoutes(route))
    }
  }

  const canShowFullAuthLayout = ProductModel.canShowAuthFullLayout(resources.products || [], selectedProduct as IProduct)
  const canShowCredentialsMessage = ProductModel.canShowCredentialsMessage(resources.products || [], selectedProduct as IProduct, layout)

  if (layout.loading && canShowFullAuthLayout) {
    return <FullScreenLoading
      logo={imagesUrls.rodobensLogoLight}
      onExit={handleReset}
      onTimeout={() => {
        handleLoading(false)
        handleError('Falha ao se comunicar com o servidor')
      }}
    />
  }

  if (layout.error && canShowFullAuthLayout) {
    return (
      <FullScreenError
        onEnter={closeFullScreenError}
        loading={layout.loading}
        onClick={() => handleFetchUserInfo(auth.level)}
        onExit={handleReset}
        message={layout.error}
        logo={imagesUrls.rodobensLogoLight}
      />
    )
  }

  if (ProductModel.mustSignLGPDTerm(auth.level, selectedProduct, auth?.lgpd?.status)) {
    return (
      <FullScreenLGPD
        termUrl={auth.lgpd?.url}
      />
    )
  }

  return (
    <>
      <CommunicationUnicoModal />

      <div className='auth-layout'>
        <Layout className="layout auth-layout__full-view-height">
          {!auth.followUpProposal && (<Navbar />)}

          <Layout className="auth-layout__layout-container auth-layout__full-height">
            {UsersModel.showSidebar(auth, selectedProduct?.slug as _productSlugs) && <Sidebar />}

            <Layout.Content className='auth-layout__content'>
              <>
                <div className={`container container--custom-height ${system.route?.meta?.centered === true ? 'container--centered-width' : ''}`}>
                  {canShowCredentialsMessage && (
                    <div className='mb-3'>
                      <MessageLittlePanel
                        message='Suas credenciais no Canal Rodobens estão desatualizadas, atualize o mais breve possível'
                        action={
                          <Button
                            size="small"
                            type="ghost"
                            className='auth-layout__warning-button '
                            onClick={() => {
                              pageHistory.push('/auth/profile')
                            }}
                          >
                            Atualizar
                          </Button>
                        }
                      />
                    </div>
                  )}

                  <Switch>{routes.map(renderRoutes)}</Switch>
                  {(Boolean(proposal.id) && auth.level === 'client') && <ContactToSupport />}

                  <ReduxDrawer />
                  <ReduxModal />

                  {progressQueue.progressQueueList.length > 0 && (
                    <ProgressQueueContainer />
                  )}
                </div>

                {!system.route.isTable && (<Footer />)}
              </>
            </Layout.Content>
          </Layout>
        </Layout>
      </div>
    </>
  )
}

export default Auth
