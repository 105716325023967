import React, { useLayoutEffect, useState } from 'react'
import MlmBaseCard from '../MlmBaseCard/MlmBaseCard'
import { IFilterDate } from '../../views/MlmDashboard/types'
import { LinkOutlined } from '@ant-design/icons'
import format from 'utils/format'
import { Skeleton } from 'antd'
import MlmRepository from 'egi/repositories/MlmRepository'

function MlmDashGeneratedLinks ({ filterDate }: IFilterDate) {
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)

  useLayoutEffect(() => {
    const abortController = new AbortController()
    async function fetchLinks () {
      setLoading(true)
      try {
        const response = await MlmRepository.links({
          signal: abortController.signal,
          params: filterDate
        })

        const { total } = response.data.data || {}
        if (!total) throw new Error('Falha ao buscar total')

        setTotal(total)
        setFailed(false)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setFailed(true)
          setLoading(false)
        }
      }
    }

    if (filterDate.createdDate && filterDate.productId) fetchLinks()
    return () => abortController.abort()
  }, [
    filterDate.createdDate,
    filterDate.productId,
    filterDate.promoterId,
    filterDate.sellerId,
    filterDate.type
  ])

  const finalColor = 'primary'

  return (
    <div className="dashboard-status-card h-100">
      {loading ? (
        <Skeleton
          active={true}
          paragraph={{ rows: 1 }}
        />
      ) : (
        <MlmBaseCard
          valueColor={finalColor}
          failed={failed}
          value={format.compressedNumber(total)}
          description='Links gerados'
          Icon={<LinkOutlined className={'dashboard-icon-size color-' + finalColor} />}
        />
      )}
    </div>
  )
}

export default MlmDashGeneratedLinks
