import EntityRoute, { PREFIX } from './EntityRoute'
class ClientRoute extends EntityRoute {
  name = 'Cliente'
  path = '/client'
  preposition = 'do'

  public form () {
    return {
      name: `Cadastrar ${this.name.charAt(0).toUpperCase() + this.name.slice(1).toLowerCase()} LGPD`,
      path: `${this.FORM_PREFIX}${this.path}/:${this.getPathId(this.path)}?`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  public corbanForm () {
    return {
      name: `Cadastrar ${this.name.charAt(0).toUpperCase() + this.name.slice(1).toLowerCase()} LGPD`,
      path: `${this.FORM_PREFIX}/corban${this.path}`,
      paramLessPath: this.FORM_PREFIX + '/corban' + this.path
    }
  }

  public corbanEditForm () {
    return {
      name: 'Editar Funcionário',
      path: `/employee/corban${this.path}/:${this.getPathId(this.path)}?`,
      paramLessPath: `/employee/corban${this.path}`
    }
  }

  public corbanEditFormOnidata () {
    return {
      name: 'Editar Funcionário',
      path: `/employee/onidata/corban${this.path}/:${this.getPathId(this.path)}?`,
      paramLessPath: `/employee/corban${this.path}`
    }
  }

  public import () {
    return {
      name: 'Importação de folha',
      path: `${this.path}/import`
    }
  }

  public pending () {
    return {
      name: 'Funcionários Pendentes',
      path: this.path + '/pending'
    }
  }

  public pendingUserOnidata () {
    return {
      name: 'Funcionários Pendentes',
      path: this.path + '/pending/onidata/:covenantId'
    }
  }

  public selectCovenantPendingUser () {
    return {
      name: 'Funcionários Pendentes',
      path: `${this.path}/select/pending-user`
    }
  }

  public dismiss () {
    return {
      name: 'Demitir Funcionário',
      path: this.PREFIX + this.path + `/dismiss/:${this.getPathId(this.path)}?`,
      paramLessPath: this.PREFIX + this.path + '/dismiss'
    }
  }

  public notification () {
    return {
      name: 'Notificações',
      path: this.PREFIX + this.path + '/notifications'
    }
  }

  public transfer () {
    return {
      name: 'Transferência de usuário',
      path: this.PREFIX + this.path + `/transfer/:${this.getPathId(this.path)}?`,
      paramLessPath: this.FORM_PREFIX + this.path + '/transfer'
    }
  }
}

class CorbanRoute extends EntityRoute {
  name = 'Corban'
  path = '/corban'
  preposition = 'do'
}

class ConfigurationRoute extends EntityRoute {
  name = 'Configurações Gerai'
  path = '/configuration'
  preposition = ''
}

class ManagerRoute extends EntityRoute {
  name = 'Gestor'
  path = '/manager'
  preposition = 'do'

  public list () {
    return {
      name: this.name + 'es',
      path: this.PREFIX + this.path + 's'
    }
  }
}

class OperatorRoute extends EntityRoute {
  name = 'Operador'
  path = '/operator'
  preposition = 'do'

  public list () {
    return {
      name: this.name + 'es',
      path: this.PREFIX + this.path + 's'
    }
  }
}

class CovenantRoute extends EntityRoute {
  name = 'Convênio'
  path = '/covenant'
  preposition = 'do'
}

class ScoreRoute extends EntityRoute {
  name = 'Score'
  path = '/score'
  preposition = 'do'

  public details () {
    return {
      name: this.name,
      path: `${this.DETAILS_PREFIX}${this.path}/:scoreId?`
    }
  }

  public form () {
    return {
      name: `Informar ${this.name.toLocaleLowerCase()}`,
      path: `${this.FORM_PREFIX}${this.path}/:${this.getPathId(this.path)}?`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }
}

class ProposalRoute extends EntityRoute {
  name = 'Proposta'
  path = '/proposal'
  preposition = 'da'

  create () {
    return {
      name: `${this.name.toLocaleLowerCase()}`,
      path: `${this.FORM_PREFIX}${this.path}/create`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  preFormalization () {
    return {
      name: 'Pré Formalização',
      path: `${this.path}/preFormalizacao,biometriaNaoEnviada`
    }
  }

  findUserCPF () {
    return {
      name: 'Contratar empréstimo',
      path: `${this.FORM_PREFIX}${this.path}/cpf`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserCovenantSelect () {
    return {
      name: 'Selecionar convênio',
      path: `${this.FORM_PREFIX}${this.path}/covenant-select`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserMethod () {
    return {
      name: 'Contratar empréstimo',
      path: `${this.FORM_PREFIX}${this.path}/method`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserInformationConfirm () {
    return {
      name: 'Contratar empréstimo',
      path: `${this.FORM_PREFIX}${this.path}/confirm-user`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserMethodSend () {
    return {
      name: 'Contratar empréstimo',
      path: `${this.FORM_PREFIX}${this.path}/method-send`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserToken () {
    return {
      name: 'Contratar empréstimo',
      path: `${this.FORM_PREFIX}${this.path}/token`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  parcelsList () {
    return {
      name: 'Antecipação de parcelas',
      path: `${this.path}/:${this.getPathId(this.path)}/parcels`
    }
  }

  configuration () {
    return {
      name: `Configuração da ${this.name.toLocaleLowerCase()}`,
      path: `${this.FORM_PREFIX}${this.path}/configuration`
    }
  }

  public listSearch () {
    return {
      name: 'Busca geral',
      path: `${this.PREFIX}/search${this.path}s`
    }
  }
}

class RhRoutes extends EntityRoute {
  name = 'Usuários RH'
  path = '/rh'
  preposition = 'do'

  public form () {
    return {
      name: 'Criação de usuários',
      path: `${this.FORM_PREFIX}${this.path}/:${this.getPathId(this.path)}?`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  public formEdit () {
    return {
      name: 'edição de usuários',
      path: `${this.FORM_PREFIX}${this.path}/edit/:${this.getPathId(this.path)}?`,
      paramLessPath: `${this.FORM_PREFIX}${this.path}/edit`
    }
  }
}

class CreditAnalystRoutes extends EntityRoute {
  name = 'Analista de Crédito'
  path = '/credit-analyst'
  preposition = 'do'
}

class JuridicalRoutes extends EntityRoute {
  name = 'Jurídico'
  path = '/juridical'
  preposition = 'do'
}

class PortabilityRoutes extends EntityRoute {
  name = 'Portabilidade'
  path = '/portability'
  preposition = 'da'

  info () {
    return {
      name: 'Portabilidade',
      path: `${this.path}`
    }
  }

  findUserCPF () {
    return {
      name: 'Simulação Portabilidade',
      path: `${this.FORM_PREFIX}${this.path}/cpf`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserCovenantSelect () {
    return {
      name: 'Selecionar convênio',
      path: `${this.FORM_PREFIX}${this.path}/covenant-select`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserMethod () {
    return {
      name: 'Simulação Portabilidade',
      path: `${this.FORM_PREFIX}${this.path}/method`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserMethodSend () {
    return {
      name: 'Simulação Portabilidade',
      path: `${this.FORM_PREFIX}${this.path}/method-send`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserInformationConfirm () {
    return {
      name: 'Simulação Portabilidade',
      path: `${this.FORM_PREFIX}${this.path}/confirm-user`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserToken () {
    return {
      name: 'Simulação Portabilidade',
      path: `${this.FORM_PREFIX}${this.path}/token`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  create () {
    return {
      name: `${this.name.toLocaleLowerCase()}`,
      path: `${this.FORM_PREFIX}${this.path}/create`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  accept () {
    return {
      name: 'Aceitar Portabilidade',
      path: `${this.FORM_PREFIX}${this.path}/accept/:portabilityId?`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }
}

class LogRoutes extends EntityRoute {
  name = 'Log'
  path = '/log'
  preposition = 'do'

  apiList () {
    return {
      name: 'Logs de acesso API',
      path: `${this.path}/api`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  diffList () {
    return {
      name: 'Logs de diff Onidata',
      path: `${this.path}/diff/onidata`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  public diffDetails () {
    return {
      name: 'Detalhes dos logs de diff Onidata',
      path: `${this.path}/diff/onidata/details/:onidataLogId?`
    }
  }

  onidataQueuesList () {
    return {
      name: 'Logs onidata Queues',
      path: `${this.path}/onidata/onidata-queues`
    }
  }

  proposalAlterations () {
    return {
      name: 'Logs de segurança da proposta',
      path: `${this.path}/proposal-alterations`
    }
  }
}

class RenegotiationRoutes extends EntityRoute {
  name = 'Renegociação'
  path = '/renegotiation'
  preposition = 'da'

  info () {
    return {
      name: 'Renegociar Crédito',
      path: `${this.path}`
    }
  }

  findUserCovenantSelect () {
    return {
      name: 'Selecionar convênio',
      path: `${this.FORM_PREFIX}${this.path}/covenant-select`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserCPF () {
    return {
      name: 'Renegociação',
      path: `${this.FORM_PREFIX}${this.path}/cpf`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserMethod () {
    return {
      name: 'Renegociar crédito',
      path: `${this.FORM_PREFIX}${this.path}/method`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserMethodSend () {
    return {
      name: 'Renegociar crédito',
      path: `${this.FORM_PREFIX}${this.path}/method-send`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserInformationConfirm () {
    return {
      name: 'Renegociar crédito',
      path: `${this.FORM_PREFIX}${this.path}/confirm-user`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  findUserToken () {
    return {
      name: 'Renegociar crédito',
      path: `${this.FORM_PREFIX}${this.path}/token`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  create () {
    return {
      name: `${this.name.toLocaleLowerCase()}`,
      path: `${this.FORM_PREFIX}${this.path}/create`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  accept () {
    return {
      name: 'Aceitar Renegociação',
      path: `${this.FORM_PREFIX}${this.path}/accept/:renegotiationId?`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }

  contracts () {
    return {
      name: 'Renegociar crédito',
      path: `${this.FORM_PREFIX}${this.path}/contracts`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }
}

class BackofficeRoutesEcp extends EntityRoute {
  name = 'Backoffice'
  path = '/backoffice'
  preposition = 'dos'
}

class RHTransfer extends EntityRoute {
  name = 'Lote'
  path = '/transfer'
  preposition = ''

  controller () {
    return {
      name: `${this.name} atual`,
      path: `${this.path}/:covenantId?`
    }
  }

  detailing () {
    return {
      name: `${this.name}s anteriores`,
      path: `${this.path}/detailing/:covenantId?`
    }
  }

  selectCovenant () {
    return {
      name: `${this.name} atual`,
      path: `${this.path}/select/currentAllotment`
    }
  }

  selectCovenantPrevAllotment () {
    return {
      name: `${this.name}s anteriores`,
      path: `${this.path}/select/prevAllotment`
    }
  }
}

class IRReport extends EntityRoute {
  name = 'Informe do IR'
  path = '/ir'
  preposition = ''

  ir () {
    return {
      name: this.name,
      path: this.path
    }
  }
}

class RHEndorsement extends EntityRoute {
  name = 'Averbação'
  path = '/endorsement'
  preposition = ''

  denied () {
    return {
      name: 'Negadas',
      path: `${this.path}/recusado`
    }
  }

  inCourse () {
    return {
      name: 'Pendentes de averbação',
      path: `${this.path}/emProgresso`
    }
  }

  waiting () {
    return {
      name: 'Portabilidades Pendente De Averbação',
      path: `${this.path}/portability/emProgresso`
    }
  }

  expired () {
    return {
      name: 'Expiradas',
      path: `${this.path}/expirado`
    }
  }

  approved () {
    return {
      name: 'Aprovados',
      path: `${this.path}/aprovado`
    }
  }

  effetive () {
    return {
      name: 'Efetivados',
      path: `${this.path}/efetivado`
    }
  }

  canceled () {
    return {
      name: 'Cancelados',
      path: `${this.path}/cancelado`
    }
  }

  closed () {
    return {
      name: 'Encerrados',
      path: `${this.path}/encerrado`
    }
  }
}

class AccessManager extends EntityRoute {
  name = 'Gestão de acesso'
  path = '/access-manager'
  preposition = ''

  client () {
    return {
      name: 'Buscar Propostas ECP por CPF',
      path: `${this.path}/client`
    }
  }

  backoffice () {
    return {
      name: 'Pesquisar Usuários Internos',
      path: `${this.path}/backoffice`
    }
  }

  backofficeDetails () {
    return {
      name: 'Detalhes do Usuário Interno',
      path: `${this.path}/backoffice/:backofficeId`
    }
  }
}

class SuspectBehavior extends EntityRoute {
  name = 'Comportamentos Suspeitos'
  path = '/suspect-behavior'
  preposition = ''

  menu () {
    return {
      name: 'Menu atividades suspeitas',
      path: `${this.path}/menu`
    }
  }

  whiteList () {
    return {
      name: 'Lista branca',
      path: `${this.path}/white-list`
    }
  }

  banList () {
    return {
      name: 'Lista de banidos',
      path: `${this.path}/ban-list`
    }
  }
}

class Reports extends EntityRoute {
  name = 'Relatório'
  path = '/report'
  preposition = 'do'
}

class OnidataCredentialsConfig extends EntityRoute {
  name = 'Configuração de acesso master (Matera)'
  path = '/config/onidata/key'
  preposition = 'do'

  info () {
    return {
      name: 'Configuração de acesso master (Matera)',
      path: `${this.path}`
    }
  }
}

const proposal = new ProposalRoute()
const irReport = new IRReport()
const client = new ClientRoute()
const corban = new CorbanRoute()
const manager = new ManagerRoute()
const operator = new OperatorRoute()
const covenant = new CovenantRoute()
const score = new ScoreRoute()
const rh = new RhRoutes()
const creditAnalyst = new CreditAnalystRoutes()
const juridical = new JuridicalRoutes()
const portability = new PortabilityRoutes()
const renegotiation = new RenegotiationRoutes()
const backoffice = new BackofficeRoutesEcp()
const report = new Reports()
const configuration = new ConfigurationRoute()

const logs = new LogRoutes()
const rhTransfers = new RHTransfer()
const rhEndorsement = new RHEndorsement()
const onidataCredentialsConfig = new OnidataCredentialsConfig()
const accessManager = new AccessManager()
const suspectBehavior = new SuspectBehavior()

/**
 * Always use prefix
 *
 * all routes have a pattern, use FORM_PREFIX or DETAILS_PREFIX
 *
 * all entities need to have a list, details and form to create and edit
 */
export const ECP = {
  DASHBOARD: {
    path: PREFIX + '/dashboard',
    name: 'Dashboard'
  },

  LGPD: {
    path: PREFIX + '/terms',
    name: 'Termo LGPD'
  },

  CLIENT_PROPOSAL_LIST: {
    path: PREFIX + '/client-proposal-list',
    name: 'Contratar Crédito'
  },

  ACCEPT_CREDIT: {
    path: PREFIX + '/accept-credit/:contractId?',
    name: 'Aceitar contrato'
  },

  CLIENT_INITIAL_PAGE: {
    path: PREFIX + '/initial-page/',
    name: 'Página Inicial'
  },

  // PROPOSAL
  PROPOSAL_FORM: proposal.form(),
  PROPOSAL_FIND_USER_CPF: proposal.findUserCPF(),
  PROPOSAL_FIND_USER_METHOD: proposal.findUserMethod(),
  PROPOSAL_FIND_USER_SELECT_COVENANT: proposal.findUserCovenantSelect(),
  PROPOSAL_FIND_USER_INFORMATION_CONFIRM: proposal.findUserInformationConfirm(),
  PROPOSAL_FIND_USER_METHOD_SEND: proposal.findUserMethodSend(),
  PROPOSAL_FIND_USER_TOKEN: proposal.findUserToken(),
  PROPOSAL_CREATE: proposal.create(),
  PROPOSALS: proposal.list(),
  PROPOSALS_SEARCH: proposal.listSearch(),
  PROPOSAL_CONFIGURATION: proposal.configuration(),
  PRE_FORMALIZATION: proposal.preFormalization(),

  PROPOSAL_DETAILS: proposal.details(),
  PROPOSAL_ANTECIPATION_PARCELS: proposal.parcelsList(),

  // CLIENT
  CLIENTS: client.list(),
  CLIENT_FORM: client.form(),
  CLIENT_DETAILS: client.details(),
  CLIENTS_IMPORT: client.import(),
  CLIENTS_PENDING: client.pending(),
  CLIENTS_PENDING_ONIDATA: client.pendingUserOnidata(),
  CLIENTS_COVENANT_SELECT_PENDING_USER: client.selectCovenantPendingUser(),
  CLIENTS_NOTIFICATIONS: client.notification(),
  CLIENTS_CORBAN_FORM: client.corbanForm(),
  CLIENTS_DISMISS: client.dismiss(),
  CLIENTS_TRANSFER: client.transfer(),

  // IR
  IR_REPORT: irReport.ir(),

  // CONFIGURATION
  CONFIGURATION: configuration.list(),

  // CORBAN
  CORBANS: corban.list(),
  CORBAN_FORM: corban.form(),
  CORBAN_EDIT_FORM: client.corbanEditForm(),
  CORBAN_EDIT_FORM_ONIDATA: client.corbanEditFormOnidata(),
  CORBAN_DETAILS: corban.details(),
  CORBAN_SUB_FORM: {
    name: 'Adicionar sub-corbans',
    path: PREFIX + '/form/subcorban/:corbanId?'
  },
  CORBAN_ASSOCIATE_CHANNELS: {
    name: 'Associar Canais',
    path: PREFIX + '/associateChannels/:corbanId?'
  },
  CORBAN_SUB_ASSOCIATE: {
    name: 'Associar Sub-corbans a Empresas',
    path: PREFIX + '/form/subcorban/associate'
  },

  // MANAGER
  MANAGERS: manager.list(),
  MANAGER_FORM: manager.form(),
  MANAGER_DETAILS: manager.details(),

  // OPERATOR
  OPERATORS: operator.list(),
  OPERATOR_FORM: operator.form(),
  OPERATOR_DETAILS: operator.details(),

  // COVENANT
  COVENANTS: covenant.list(),
  COVENANT_FORM: covenant.form(),
  COVENANT_DETAILS: covenant.details(),

  // Score
  SCORE: score.details(),
  SCORE_FORM: score.form(),

  // RH
  RH: rh.list(),
  RH_CREATE: rh.form(),
  RH_EDIT: rh.formEdit(),

  RH_DETAILS: rh.details(),

  CREDIT_ANALYST: creditAnalyst.list(),
  JURIDICAL: juridical.list(),

  // PORTABILITY
  PORTABILITY_FIND_USER_CPF: portability.findUserCPF(),
  PORTABILITY_FIND_USER_METHOD: portability.findUserMethod(),
  PORTABILITY_FIND_USER_SELECT_COVENANT: portability.findUserCovenantSelect(),
  PORTABILITY_FIND_USER_METHOD_SEND: portability.findUserMethodSend(),

  PORTABILITY_FIND_USER_INFORMATION_CONFIRM: portability.findUserInformationConfirm(),
  PORTABILITY_FIND_USER_TOKEN: portability.findUserToken(),
  PORTABILITY: portability.info(),
  PORTABILITY_CREATE: portability.create(),
  PORTABILITY_ACCEPT: portability.accept(),

  // RENEGOTIATION
  RENEGOTIATION_FIND_USER_CPF: renegotiation.findUserCPF(),
  RENEGOTIATION_FIND_USER_SELECT_COVENANT: renegotiation.findUserCovenantSelect(),
  RENEGOTIATION_FIND_USER_METHOD: renegotiation.findUserMethod(),
  RENEGOTIATION_FIND_USER_METHOD_SEND: renegotiation.findUserMethodSend(),
  RENEGOCIATION_FIND_USER_INFORMATION_CONFIRM: renegotiation.findUserInformationConfirm(),
  RENEGOTIATION_FIND_USER_TOKEN: renegotiation.findUserToken(),
  RENEGOTIATE_CREDIT: renegotiation.info(),
  RENEGOTIATION_CREATE: renegotiation.create(),
  RENEGOTIATION_ACCEPT: renegotiation.accept(),
  RENEGOTIATION_CONTRACTS: renegotiation.contracts(),

  // BACKOFFICE
  BACKOFFICES: backoffice.list(),
  BACKOFFICE_FORM: backoffice.form(),
  BACKOFFICE_DETAILS: backoffice.details(),

  LOGS_API: logs.apiList(),
  ONIDATA_LOGS: logs.diffList(),
  ONIDATA_LOGS_DETAILS: logs.diffDetails(),
  LOGS_MENU: logs.list(),
  LOGS_ONIDATA_QUEUES: logs.onidataQueuesList(),
  LOGS_PROPOSAL_ALTERATIONS: logs.proposalAlterations(),

  REPORTS: report.list(),

  // RH TRANSFER TO COMPANY
  RH_TRANSFER_TO_COMPANY: rhTransfers.controller(),
  RH_TRANSFER_DETAILING: rhTransfers.detailing(),
  RH_TRANSFER_SELECT: rhTransfers.selectCovenant(),

  RH_TRANSFER_SELECT_PREV_ALLOTMENT: rhTransfers.selectCovenantPrevAllotment(),

  // RH ENDORESEMENT
  RH_ENDORSEMENT_DENIED: rhEndorsement.denied(),
  RH_ENDORSEMENT_IN_COURSE: rhEndorsement.inCourse(),
  RH_ENDORSEMENT_WAITING: rhEndorsement.waiting(),
  RH_ENDORSEMENT_EXPIRED: rhEndorsement.expired(),
  RH_ENDORSEMENT_APPROVED: rhEndorsement.approved(),
  RH_ENDORSEMENT_EFFETIVE: rhEndorsement.effetive(),
  RH_ENDORSEMENT_CANCELED: rhEndorsement.canceled(),
  RH_ENDORSEMENT_CLOSED: rhEndorsement.closed(),

  // ONIDATA CREDENTIALS
  ONIDATA_CREDENTIALS: onidataCredentialsConfig.info(),

  // ACCESS MANAGER
  ACCESS_MANAGER_CLIENT: accessManager.client(),
  ACCESS_MANAGER_BACKOFFICE: accessManager.backoffice(),
  ACCESS_MANAGER_BACKOFFICE_DETAILS: accessManager.backofficeDetails(),

  SUSPECT_BEHAVIOR_MENU: suspectBehavior.menu(),
  SUSPECT_BEHAVIOR_WHITE_LIST: suspectBehavior.whiteList(),
  SUSPECT_BEHAVIOR_BAN_LIST: suspectBehavior.banList()
}
