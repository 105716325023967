import { Button, Row } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { TableECPF } from 'components/ServerTable/ServerTable'
import { R$ } from 'ecp/app/Proposals/proposalFunctions'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import { useProposalFlow } from 'ecpf/hooks/useProposalFlow'
import { ProposalCustomerFlowClientType } from 'ecpf/models/ProposalFuncaoModel'
import { IContractECPF } from 'ecpf/repositories/OperationsECPFRepository'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useContracts } from '../../hooks/useOperationsECPF'

interface ISelectContractsECPF {
  onRedirect: (flowType: ProposalCustomerFlowClientType) => void
  description: string
  initialPath: string
}

const columns: ColumnsType<IContractECPF> = [
  {
    title: 'Contrato',
    dataIndex: 'numeroContrato'
  },
  {
    title: 'Parcelas pagas',
    dataIndex: '',
    render: (row: IContractECPF) => {
      const payedDue = (row?.quantidadeParcelas || 0) - (row?.quantidadeParcelasAbertas || 0)
      return `${payedDue} de ${row?.quantidadeParcelas || 0}`
    }
  },
  {
    title: 'Saldo devedor',
    dataIndex: 'saldoData',
    render: (saldoData: number) => saldoData ? R$(saldoData) : '-'
  }
]

export default function SelectContractsECPF ({ onRedirect, description, initialPath }: ISelectContractsECPF) {
  const { contracts, fetchContracts, loading, error } = useContracts()
  const {
    customerFlow,
    clientCovenant,
    updateSelectContractsFlow
  } = useProposalFlow()
  const history = useHistory()

  const [selectedContracts, setSelectedContracts] = React.useState<string[]>([])

  React.useEffect(() => {
    if (!customerFlow || !clientCovenant) {
      history.push(initialPath)
    }
    const abortController = new AbortController()
    const params = {
      codigoEmpregador: clientCovenant?.origens?.codigoEmpregador || '',
      codigoPromotor: clientCovenant?.origens?.codigoPromotor || '',
      numeroMatricula: customerFlow?.cliente.numeroMatricula || '',
      documento: customerFlow?.cliente.documento || ''
    }

    fetchContracts(params, abortController)
    return () => {
      abortController.abort()
    }
  }, [customerFlow, clientCovenant])

  const onContinue = () => {
    updateSelectContractsFlow({ selectedContracts, contracts })
    onRedirect(ProposalCustomerFlowClientType.clientWithEmployer)
  }

  return (
    <React.Fragment>
      <EcpCard className="renegotiation">
        <h2 className='renegotiation__title'>Selecione seus contratos</h2>
        <p className='renegotiation__paragraph mb-4'>{description}</p>

        <TableECPF
          canSearch={false}
          columns={columns}
          data={contracts || []}
          loading={loading}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys) => setSelectedContracts([...selectedRowKeys] as string[])
          }}
          rowKey='numeroContrato'
          errorDetails={error}
          errorText={error?.message || ''}
        />
      </EcpCard>

      <Row justify="end" >
        <Button
          type="primary"
          onClick={onContinue}
          disabled={selectedContracts.length < 1}
        >
          Continuar
        </Button>
      </Row>
    </React.Fragment>
  )
}
