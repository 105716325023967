
import { AxiosRequestConfig } from 'axios'
import { AuthorizationsUser, SignInCredentials } from 'ecp/repositories/LoginRepository'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import { ICheckTypistResponse } from 'ecpf/repositories/SigninECPFRepository'
import api from 'services/api'

export interface IResetPasswordResponse {
  expiresIn: number
  isVinculateWithOnidata: boolean
  email: string
}

class LoginRepositoryEgi extends Repository {
  async reset (data: { identifier: string, withMFA?: boolean }, config?: AxiosRequestConfig) {
    return Repository.handle<IResetPasswordResponse | ICheckTypistResponse>(() =>
      this.api.post(`${this.path}/password/reset`, data, config)
    )
  }

  async login (credentials: SignInCredentials, config?: AxiosRequestConfig) {
    return Repository.handle<IResponseBase>(() =>
      this.api.post(this.path, credentials, config)
    )
  }

  async authorizations (data: SignInCredentials, config?: AxiosRequestConfig) {
    return Repository.handle<{
      users: Array<AuthorizationsUser>
    }>(() =>
      this.api.post(`${this.path}/authorizations`, data, config)
    )
  }
}

export default new LoginRepositoryEgi({ api, path: '/login' })
