import React, { useState } from 'react'
import { Button, Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { InputPassword } from 'components'
import Input from 'components/Inputs/Input/Input'
import { swalError } from 'components/SwalError/SwalError'
import { TokenService } from 'ecpf/services/tokenService'
import { TokenMethodEnum } from 'ecpf/components/TokenValidator/TokenValidator'
import { TokenValidationMessageTemplateType } from 'ecpf/models/TokenValidationModel'
import { ICheckTypistResponse, SigninMethodCheckTypistEnum } from 'ecpf/repositories/SigninECPFRepository'
import { B2CService, B2CURLNotFound } from 'ecpf/services/b2cService'
import { CookieButton, OIDCLink } from 'egi/app/Signin/Signin'
import { Link, useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import swal from 'utils/swal'
import { validateCpf, validateResponse } from 'utils/validate'
import SigninB2CModalECPF, { SinginB2CFlowOptionsEnum } from '../../../SigninB2CECPF/views/SigninB2CModalECPF/SigninB2CModalECPF'
import { SigninService } from 'ecpf/services/signinService'
import { TokenValidatorConfirmUnauth } from '../../../ForgotPasswordECPF/views/ForgotPasswordECPF/ForgotPasswordECPF'

interface ISigninECPFPasswordForm {
  password: string
}

const SigninPasswordECPF = ({
  onSubmitPassword,
  onDecrementStep,
  loading
}: { onSubmitPassword: (password: string) => void, onDecrementStep: () => void, loading: boolean }) => {
  const [form] = useForm()

  const onSubmit = async (values: ISigninECPFPasswordForm) => {
    try {
      onSubmitPassword(values.password)
    } catch (err) {
      message.error(err?.message)
    }
  }

  return (
    <Form
      layout='vertical'
      className='unauth-form'
      form={form}
      onFinish={onSubmit}
    >
      <Form.Item
        name='password'
        label={<label className="simulator-label ml-2">Senha</label>}
      >
        <InputPassword
          data-cy="test-login-password"
          placeholder='Senha'
          className="unauth-inputs"
        />
      </Form.Item>

      <div className='mb-4'>
        <Link
          to={UNAUTHS_PATHS.FORGOT_ECPF}
          className='color-primary underline unauth-bottom-label'
        >
          Esqueceu a senha?
        </Link>
      </div>

      <Button
        loading={loading}
        type='primary'
        className='w-100 unauth-buttons'
        htmlType='submit'
      >
        Entrar
      </Button>

      <div className='text-center'>
        <Link
          to={UNAUTHS_PATHS.MAIN}
          className='underline'
          onClick={() => onDecrementStep()}
        >
          Voltar
        </Link>
      </div>
    </Form>
  )
}

interface ISigninECPForm {
  cpf: string
}

const SigninCPFECPF = ({ onSubmitCpf }: { onSubmitCpf: (values: ISigninECPForm) => Promise<void> }) => {
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Partial<ISigninECPForm>>({})

  const [showLevelFlowModal, setShowLevelFlowModal] = useState(false)
  const [clientLoading, setClientLoading] = useState(false)

  const onSubmit = async (values: ISigninECPForm) => {
    setLoading(true)
    try {
      await onSubmitCpf(values)
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
      }
    } finally {
      setLoading(false)
    }
  }

  const onGenerateUri = async (documento: string) => {
    try {
      setClientLoading(true)

      const b2cService = new B2CService()
      const response = await b2cService.fetchRedirectUrl({ params: { documento } })

      const { url } = response.data?.data || {}

      if (!url) throw new B2CURLNotFound()
      window.location.href = url
    } catch (err) {
      swalError({ err })
    } finally {
      setClientLoading(false)
    }
  }

  return (
    <>
      <SigninB2CModalECPF
        loadingClient={clientLoading}
        visible={showLevelFlowModal}
        onClose={() => setShowLevelFlowModal(false)}
        onSelect={(flowOption) => {
          if (flowOption === SinginB2CFlowOptionsEnum.client) {
            onGenerateUri(form.getFieldValue('cpf'))
          }

          if (flowOption === SinginB2CFlowOptionsEnum.corban) {
            setShowLevelFlowModal(false)
            onSubmit({ cpf: form.getFieldValue('cpf') })
          }
        }}
      />

      <Form
        onFinish={() => setShowLevelFlowModal(true)}
        layout='vertical'
        className='unauth-form'
        form={form}
      >
        <Form.Item
          name='cpf'
          label={<label className="simulator-label ml-2">CPF</label>}
          help={errors?.cpf}
          validateStatus={errors.cpf && 'error'}
          rules={[
            { required: true, message: 'CPF é obrigatório.' },
            () => ({
              validator (_, value: string) {
                if (!value) return Promise.resolve()

                if (!validateCpf(value)) {
                  return Promise.reject(new Error('CPF inválido.'))
                }

                return Promise.resolve()
              }
            })
          ]}
        >
          <Input
            className="unauth-inputs"
            placeholder='Digite o cpf'
            mask="cpf"
          />
        </Form.Item>

        <Button
          className='w-100 unauth-buttons mb-3'
          htmlType='submit'
          loading={loading}
        >
          Entrar
        </Button>
      </Form>

      <OIDCLink />

      <CookieButton />
    </>
  )
}

type IOnSubmitAlternativeFields = (fields: { identifier: string, password: string }) => void

const SigninECPF = ({ onSubmitAlternativeFields, loading }: { onSubmitAlternativeFields: IOnSubmitAlternativeFields, loading: boolean }) => {
  const [step, setStep] = useState(1)
  const [identifier, setIdentifier] = useState('')
  const [typistResponse, setTypistResponse] = useState<ICheckTypistResponse>()

  const history = useHistory()

  const onIncrementStep = () => {
    setStep(prev => prev + 1)
  }

  const onDecrementStep = () => {
    setStep(prev => prev - 1)
  }

  const onResetStep = () => {
    setStep(1)
  }

  const redirectToFirstAccessEcpf = (identifier: string, tokenId: string) => {
    history.push(`${UNAUTHS_PATHS.SIGNUP}?identifier=${identifier}&tokenValidationId=${tokenId}&isEcpf=true`)
  }

  const onSubmitToken = async (token: string, identifier: string, typistResponse?: ICheckTypistResponse) => {
    const tokenService = new TokenService()
    const response = await tokenService.submit(token, typistResponse?.email || '', TokenMethodEnum.email)

    if (typistResponse?.signinMethod === SigninMethodCheckTypistEnum.firstAccess) {
      redirectToFirstAccessEcpf(identifier, typistResponse.tokenId)
    }

    onIncrementStep()

    return response
  }

  const onSubmitCpf = async (values: ISigninECPForm) => {
    try {
      const signinService = new SigninService()
      const response = await signinService.checkTypist({ identifier: values.cpf })

      setTypistResponse(response.data.data)
      setIdentifier(values.cpf)
      onIncrementStep()
    } catch (err) {
      if (err?.data?.redirectTo) {
        history.push(err?.data?.redirectTo)
      } else {
        swal.htmlBasic({
          text: err?.message,
          icon: 'warning',
          title: 'Atenção',
          customClass: {
            content: 'signin__basic-swam-html'
          }
        })
      }

      throw err
    }
  }

  const handleResendToken = async (contactInfo: string, method: TokenMethodEnum | null, messageTemplate: TokenValidationMessageTemplateType) => {
    const tokenService = new TokenService()
    return tokenService.resend(contactInfo, method, messageTemplate)
  }

  if (step === 1) {
    return (
      <>
        <SigninCPFECPF
          onSubmitCpf={onSubmitCpf}
        />
      </>
    )
  }

  if (step === 2) {
    return (
      <TokenValidatorConfirmUnauth
        email={typistResponse?.email}
        onResendToken={() => handleResendToken(typistResponse?.email || '', TokenMethodEnum.email, TokenValidationMessageTemplateType.userValidation)}
        onGoBack={onDecrementStep}
        onSubmit={
          (token) => onSubmitToken(token, identifier, typistResponse)
        }
      />
    )
  }

  return (
    <SigninPasswordECPF
      loading={loading}
      onDecrementStep={onResetStep}
      onSubmitPassword={(password: string) => {
        onSubmitAlternativeFields({ identifier, password })
      }}
    />
  )
}

export default SigninECPF
