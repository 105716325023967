import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const ManagerList = loadable(
  () => timeout(import(
    /* webpackChunkName: "Managers" */
    'ecp/app/Managers/views/ManagerList/ManagerList'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const ManagerDetails = loadable(
  () => timeout(import(
    /* webpackChunkName: "Managers" */
    'ecp/app/Managers/views/ManagerDetails/ManagerDetails'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const ManagerForm = loadable(
  () => timeout(import(
    /* webpackChunkName: "Managers" */
    'ecp/app/Managers/views/ManagerForm/ManagerForm'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

export { ManagerDetails, ManagerForm, ManagerList }
