import { AxiosRequestConfig } from 'axios'
import { IDataErrorECPF } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'
import dayjs from 'dayjs'
import { IResponseBase } from 'ecp/repositories/Repository'
import { returnProposalType } from 'ecpf/app/ProposalECPF/ProposalECPFFunctions'
import { IProposalSubmissionDataBody, ISimulationECPFBody, ISimulationECPFResponse, MetaCalculo, ProposalType } from 'ecpf/app/ProposalECPF/ProposalECPFInterfaces'
import { IProposalSimulationMinMaxEcpf, ProposalSimulationFuncaoModel } from 'ecpf/models/ProposalFuncaoModel'
import CovenantECPFRepository, { ICovenantECPF, ICovenantECPFLimit } from 'ecpf/repositories/CovenantECPFRepository'
import OriginsECPFRepository from 'ecpf/repositories/OriginsECPFRepository'
import ProposalECPFRepository, { ICheckProposalPayload } from 'ecpf/repositories/ProposalECPFRepository'
import SimulationECPFRepository from 'ecpf/repositories/SimulationECPFRepository'
import { IReduxProposalFlow } from 'store/modules/proposalFlow/actions'
import format from 'utils/format'

export class ConditionalCovenant extends Error {
  name = 'INVALID_COVENANT'
  message = 'Simulação não atende o(s) convênio(s)'
  data: IDataErrorECPF & { conditionals: any }

  constructor (data: IDataErrorECPF & { conditionals: any }) {
    super()

    this.data = data
  }
}

class CovenantLimitNotFound extends Error {
  name = 'COVENANT_LIMIT_NOT_FOUND'
  message = 'Limites do convênio não encontrados'
  data = { erros: [{ codigo: null, mensagem: 'Limites do convênio não encontrados' }] }
}

class CovenantInvalidDeadline extends Error {
  name = 'CONVENANT_INVALID_DEADLINE'
  message = 'Não foi possível realizar uma simulação com os dados informados. Prazo máximo disponível é zero'
  data = {
    erros: [
      {
        codigo: null,
        mensagem: 'Não foi possível realizar uma simulação com os dados informados. Prazo máximo disponível é zero'
      }
    ]
  }
}

interface IFuncaoErrorItemReponse {
  codigo: string | null, mensagem: string
}
interface IFuncaoErrorResponse {
  erros: Array<IFuncaoErrorItemReponse>
}
export class CovenantNotFound extends Error {
  name = 'COVENANT_NOT_FOUND'
  message = 'Convênio não encontrado'
  data: IFuncaoErrorResponse = { erros: [{ codigo: null, mensagem: 'Convênio não encontrado' }] }

  constructor (...extraError: Array<IFuncaoErrorItemReponse>) {
    super()

    if (extraError.length > 0) {
      const errors = this.data.erros
      errors.push(...extraError)

      this.data = { erros: errors }
    }
  }
}

export class ConvenantLimitNotFound extends Error {
  name = 'COVENANT_LIMIT_NOT_FOUND'
  message = 'Limite não disponível com os dados informados'
  observacao?: string
  data: IDataErrorECPF

  constructor (observacao?: string) {
    super()

    this.observacao = observacao
    this.data = { erros: [{ codigo: null, mensagem: observacao || '-' }] }
  }
}

class CovenantClientWithoutAge extends Error {
  name = 'COVENANT_CLIENT_WITHOUT_AGE'
  message = 'Idade do cliente não encontrada'
  data = { erros: [{ codigo: null, mensagem: 'Idade do cliente não encontrada' }] }
}

class OrgaosByCNPJNotFound extends Error {
  name = 'ORGAOS_BY_CNPJ_NOT_FOUND'
  message = 'Convênios não encontrados'
  data = {
    erros: [
      {
        codigo: null,
        mensagem: 'Convênios não encontrados'
      }
    ]
  }
}

export class InvalidCNPJ extends Error {
  name = 'INVALID_CNPJ'
  message = 'Digite um CNPJ válido.'
  data = {
    erros: [
      {
        codigo: null,
        mensagem: 'Digite um CNPJ válido.'
      }
    ]
  }
}

class EmployerNotFound extends Error {
  name = 'EMPLOYER_NOT_FOUND'
  message = 'Empregador não encontrado'
  data = { erros: [{ codigo: null, mensagem: 'Empregador não encontrado' }] }
}

export class ConditionsNotFound extends Error {
  name = 'CONDITIONS_NOT_FOUND'
  message = 'Condições não encontradas'
  data = { erros: [{ codigo: null, mensagem: 'Condições não encontradas' }] }
}

export class ContractLogsNotFound extends Error {
  name = 'CONTRACT_LOGS_NOT_FOUND'
  message = 'Logs de contrato não encontrados'
  data = { erros: [{ codigo: null, mensagem: 'Logs de contrato não encontrados' }] }
}

export class ProposalIdNotFound extends Error {
  name = 'PROPOSAL_ID_NOT_FOUND'
  message = 'ID da proposta não encontrado'
}

export class ProposalService {
  async fetchCovenantClient (cpf: string, config: AxiosRequestConfig) {
    const response = await CovenantECPFRepository.queryClientCovenant(format.onlyNumber(cpf), config)

    const { result } = response?.data?.data || {}
    return result
  }

  getAllConditionals (condicoes: ISimulationECPFResponse['condicoes']) {
    const convenioConditionals = (condicoes ?? []).filter(
      condicao => Boolean(condicao.convenio?.observacao)
    )

    return (convenioConditionals ?? []).map(conditional => conditional.convenio)
  }

  async onSubmitSimulation (simulation: ISimulationECPFBody, config?: AxiosRequestConfig) {
    const response = await SimulationECPFRepository.create(simulation, config)
    const credito: ISimulationECPFResponse = response?.data?.data?.credito

    const onlySentCovenants = ProposalSimulationFuncaoModel.getOnlyClientCovenantInSimulationResponse(credito, simulation)

    const conditionals = this.getAllConditionals(onlySentCovenants)
    if (!Array.isArray(credito?.condicoes)) throw new ConditionsNotFound()

    if (conditionals.length > 0) {
      const errors = conditionals.map(conditional => ({
        codigo: null,
        mensagem: conditional.observacao
      }))

      throw new ConditionalCovenant({ erros: errors, conditionals })
    }

    return credito
  }

  async queryEmployer (cnpj: string) {
    const response = await CovenantECPFRepository.queryEmployerCovenant(cnpj)
    const result = response.data.data?.result

    if (!result) throw new EmployerNotFound()

    return result
  }

  async fetchOrgaoByCNPJ (cnpj: string, config: AxiosRequestConfig) {
    const requestConfig = {
      params: { documento: cnpj },
      ...config
    }

    const response = await OriginsECPFRepository.listOrganizationByCNPJ(requestConfig)

    const { orgaos } = response.data.data ?? {}
    if (!orgaos) {
      throw new OrgaosByCNPJNotFound()
    }

    return orgaos
  }

  async fetchOrigensAndConvenioByOrgaoCNPJ (cnpj: string, config: AxiosRequestConfig) {
    const [orgao] = await this.fetchOrgaoByCNPJ(cnpj, config)
    const conveniosRes = await this.getCovenant(orgao.codigoEmpregador, returnProposalType(ProposalType.new), config)

    const [convenio] = conveniosRes.convenios ?? []
    if (!convenio) throw new CovenantNotFound()

    const empregadorRequestConfig = {
      params: {
        codigo: orgao.codigoEmpregador,
        limit: 1,
        offset: 1
      }
    }

    const empregadorRes = await OriginsECPFRepository.listEmployer(empregadorRequestConfig)
    const [empregador] = empregadorRes.data.data?.resultados ?? []

    if (!empregador) throw new EmployerNotFound()

    return {
      origens: {
        codigoEmpregador: orgao.codigoEmpregador,
        codigoOrgao: orgao.codigo,
        codigoPromotor: empregador?.promotor?.codigo
      },
      convenios: [
        {
          codigo: convenio.codigo,
          descricao: convenio.descricao,
          grupoConvenio: empregador.grupoConvenio
        }
      ]
    }
  }

  async createProposal (values: IProposalSubmissionDataBody): Promise<IResponseBase<{ result: Array<{ sensediaId: string }> }>> {
    return ProposalECPFRepository.create(values)
  }

  getCovenant = async (codigoEmpregador: string, tipoOperacao: string, config: AxiosRequestConfig) => {
    const params = {
      codigoEmpregador,
      tipoOperacao,
      tipoProdutoConvenio: 'Consignado'
    }

    const response = await CovenantECPFRepository.fetchLimits({ params, ...config })
    const result = response?.data?.data?.result

    if (!result) throw new CovenantLimitNotFound()

    return result
  }

  async getCovenantLimits (codigoEmpregador: string, tipoOperacao: string, convenio: ICovenantECPF, config: AxiosRequestConfig) {
    const convenioResponse = await this.getCovenant(codigoEmpregador, tipoOperacao, config)

    if (!convenio?.codigo) {
      throw new CovenantNotFound()
    }

    const clientCovenant = convenioResponse.convenios.find(
      covenant => covenant.codigo === convenio?.codigo
    )

    if (!clientCovenant) {
      throw new CovenantNotFound(
        { codigo: null, mensagem: 'Convênio do cliente: ' + `${convenio?.codigo} - ${convenio?.descricao}` },
        { codigo: null, mensagem: 'Convênios disponiveis: ' + convenioResponse.convenios.map(convenio => `${convenio.codigo} - ${convenio.descricao}`).join(', ') }
      )
    }

    return clientCovenant
  }

  async getLimitByClientAge (codigoEmpregador: string, tipoOperacao: string, client: IReduxProposalFlow['customerFlow'], convenio: ICovenantECPF, config: AxiosRequestConfig): Promise<ICovenantECPFLimit> {
    if (!client?.cliente?.dataNascimento) throw new CovenantClientWithoutAge()

    const clientAge = dayjs().diff(dayjs(format.brDateToDate(client?.cliente?.dataNascimento)), 'year')
    const limits = await this.getCovenantLimits(codigoEmpregador, tipoOperacao, convenio, config)

    const correctLimits = limits.limites.filter(
      limit => limit.idade >= clientAge
    )

    if (!correctLimits.length) throw new CovenantLimitNotFound()
    if (correctLimits[0].prazoMaximo === 0) {
      throw new CovenantInvalidDeadline()
    }

    return correctLimits[0]
  }

  /*
   * Extracts the limit from the covenant response message.
   * Message example:
   * Valor da parcela 1.000.000,00 não pode ser maior que o limite disponível 400,00.
   */
  extractLimitFromCovenantResponse (inputString: string) {
    if (!inputString) return null

    const regex = /limite disponível (\d{1,3}(?:\.\d{3})*,\d{2}|\d+)/
    const match = inputString.match(regex)

    if (!match) return null

    let limiteDisponivelStr = match[1]
    limiteDisponivelStr = limiteDisponivelStr.replace(/\./g, '').replace(',', '.')

    return parseFloat(limiteDisponivelStr)
  }

  /*
   * Extracts the quantidade de vezes from the response message.
   * Message example:
   * Valor do cálculo utilizando a renda e quantidade de vezes '6000' é menor que o valor solicitado 9169,86.
   */
  extractValorSolicitadoFromCovenantResponse (inputString: string) {
    const regex = /quantidade de vezes '(\d+)'/
    const match = inputString.match(regex)

    if (!match) return null

    return parseInt(match[1])
  }

  async getSimulationLimits ({
    customerFlow,
    clientCovenant,
    proposalType,
    prazoMaximo,
    condicao,
    config,
    selectContractsFlow
  }: {
    customerFlow: IReduxProposalFlow['customerFlow'],
    clientCovenant: IReduxProposalFlow['clientCovenant'],
    proposalType: ProposalType,
    prazoMaximo: number,
    condicao: ISimulationECPFBody['condicaoCredito'],
    config: AxiosRequestConfig,
    selectContractsFlow?: IReduxProposalFlow['selectContractsFlow']
  }): Promise<IProposalSimulationMinMaxEcpf | undefined> {
    try {
      const simulation = ProposalSimulationFuncaoModel.formatToSend({
        condicaoCredito: condicao,
        customerFlow,
        clientCovenant,
        proposalType,
        selectContractsFlow
      })

      const simulationRes = await this.onSubmitSimulation(simulation, config)
      const { valorParcela, quantidadeParcelas, valorSolicitado, despesas } = simulationRes?.condicoes[0] ?? {}

      return ProposalSimulationFuncaoModel.generateMinMaxSimulation(
        valorParcela,
        valorSolicitado,
        quantidadeParcelas,
        despesas
      )
    } catch (error) {
      if (error instanceof ConditionalCovenant) {
        const { data } = error
        const { conditionals } = data

        const maxValorParcela = this.extractLimitFromCovenantResponse(conditionals?.[0]?.observacao)
        if (maxValorParcela) {
          const fakeCondicao = {
            metaCalculo: MetaCalculo.ValorSolicitado,
            valorParcela: maxValorParcela,
            quantidadeParcelas: prazoMaximo
          }

          return this.getSimulationLimits({
            customerFlow,
            clientCovenant,
            proposalType,
            prazoMaximo,
            condicao: fakeCondicao,
            config,
            selectContractsFlow
          })
        }

        const maxValorSolicitado = this.extractValorSolicitadoFromCovenantResponse(conditionals?.[0]?.observacao)
        if (maxValorSolicitado) {
          const fakeCondicao = {
            metaCalculo: MetaCalculo.ValorParcela,
            valorSolicitado: maxValorSolicitado,
            quantidadeParcelas: prazoMaximo
          }

          return this.getSimulationLimits({
            customerFlow,
            clientCovenant,
            proposalType,
            prazoMaximo,
            condicao: fakeCondicao,
            config,
            selectContractsFlow
          })
        }

        throw new ConvenantLimitNotFound(conditionals?.[0]?.observacao)
      }

      throw error
    }
  }

  async getRenegotiationSimulationLimits ({
    customerFlow,
    clientCovenant,
    proposalType,
    totalOutstandingBalance,
    quantidadeParcelas,
    selectContractsFlow
  }: {
    customerFlow: IReduxProposalFlow['customerFlow'],
    clientCovenant: IReduxProposalFlow['clientCovenant'],
    proposalType: ProposalType,
    totalOutstandingBalance: number,
    quantidadeParcelas?: number,
    selectContractsFlow: IReduxProposalFlow['selectContractsFlow']
  }, abortController: AbortController) {
    try {
      const initialContitional = {
        metaCalculo: MetaCalculo.ValorParcela,
        valorSolicitado: totalOutstandingBalance,
        quantidadeParcelas
      }

      const simulation = ProposalSimulationFuncaoModel.formatToSend({
        condicaoCredito: initialContitional,
        customerFlow,
        clientCovenant,
        proposalType,
        selectContractsFlow
      })

      const simulationRes = await this.onSubmitSimulation(simulation, { signal: abortController.signal })
      const { valorParcela, quantidadeParcelas: quantidadeParcelasResponse, valorSolicitado, despesas } = simulationRes?.condicoes[0] ?? {}
      const simulationLimits = ProposalSimulationFuncaoModel.generateMinMaxSimulation(
        valorParcela,
        valorSolicitado,
        quantidadeParcelasResponse,
        despesas
      )

      const creditoFactory = ProposalSimulationFuncaoModel.simulationReponseFactory(simulationRes, simulation)
      return { simulationLimits, creditoFactory }
    } catch (error) {
      if (!abortController.signal.aborted) {
        if (error instanceof ConditionalCovenant) {
          const { data } = error
          const { conditionals } = data || {}

          const maxValorSolicitado = this.extractValorSolicitadoFromCovenantResponse(conditionals?.[0]?.observacao)
          if (!maxValorSolicitado) throw new ConvenantLimitNotFound(conditionals?.[0]?.observacao)
        }

        throw error
      }
    }
  }

  async fetchContractLogs (proposalId: string, stepSlug: string, config?: AxiosRequestConfig) {
    const response = await ProposalECPFRepository.getContractLogs(proposalId, stepSlug, config)
    const contractLogs = response?.data?.data?.contractStatus

    if (!contractLogs) throw new ContractLogsNotFound()
    return contractLogs
  }

  async fetchPendingStep (sensideaId: string, config?: AxiosRequestConfig) {
    return ProposalECPFRepository.getPendingStep(sensideaId, config)
  }

  async details (proposalId?: string, config?: AxiosRequestConfig) {
    if (!proposalId) throw new ProposalIdNotFound()
    return ProposalECPFRepository.details(proposalId, config)
  }

  async fetchList (data: ICheckProposalPayload, config?: AxiosRequestConfig) {
    return ProposalECPFRepository.list(data, config)
  }
}
