import { AxiosRequestConfig } from 'axios'
import { IDenyEndorsementBody, IDynamicProposal, IDynamicProposalStepResponse, IGetActionResponse, IProposalDepartmentReponse, IProposalHistory, ISendEmail } from 'ecp/app/Proposals/proposalInterfaces'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class ProposalRepository extends Repository {
  async getDepartments (id: string, config?: AxiosRequestConfig): Promise<IResponseBase<IProposalDepartmentReponse>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}/department`, config)
    )
  }

  async getSteps ({ departmentId, proposalId }: { departmentId: string, proposalId: string }, config?: AxiosRequestConfig): Promise<IResponseBase<IDynamicProposalStepResponse>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${proposalId}/department/${departmentId}/step`, config)
    )
  }

  async getAction <ResponseAction> ({ departmentId, proposalId, stepId, signed }: { departmentId: string, proposalId: string, stepId: string, signed: boolean }, config?: AxiosRequestConfig): Promise<IResponseBase<IGetActionResponse<ResponseAction>>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${proposalId}/department/${departmentId}/step/${stepId}?signed=${signed}`, config)
    )
  }

  async find (id: string, config?: AxiosRequestConfig): Promise<IResponseBase<{ proposal: IDynamicProposal.Model }>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}`, config)
    )
  }

  async sendEmail (email: ISendEmail.Values, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/boleto/email`, email, config)
    )
  }

  async getProposalHistory (id: string, config: AxiosRequestConfig): Promise<IResponseBase<IProposalHistory.Response>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}/history`, config)
    )
  }

  async denyEndosement (proposalId: string[], body: IDenyEndorsementBody, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/endorsement/deny/${proposalId}`, body, config)
    )
  }

  async approveEndosement (proposalId: string[], config?: AxiosRequestConfig): Promise<IResponseBase<{requestId: string}>> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/endorsement/bulk`, { proposalIds: proposalId }, config)
    )
  }

  async getPendingProposals (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/pending`, config)
    )
  }

  async accessClientDetails (config?: AxiosRequestConfig) {
    return Repository.handle<{proposal: Array<any>}>(() =>
      this.api.get(`${this.path}/access-manager/clients/details`, config)
    )
  }
}

export default new ProposalRepository({ api, path: 'ecp/proposals' })
