import { ArrowRightOutlined, PoweroffOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Result, Space } from 'antd'
import DynamicColorButton from 'ecp/components/DynamicColorButton/DynamicColorButton'
import ProductModel from 'ecp/models/ProductModel'
import { FullScreenPageLogo, FullScreenProducts } from 'layouts/FullScreenLoading/FullScreenLoading'
import React from 'react'

type Props = {
  logo: string
  message: string
  onClick: () => void
  loading: boolean
  onExit: () => void
  onEnter: () => void
}
export default function FullScreenError ({ logo, message, onClick, onExit, loading, onEnter }: Props) {
  return (
    <div className='full-screen-error'>
      <div className='mb-6'>
        <FullScreenPageLogo logo={logo} />
      </div>

      <div className='full-screen-error__card mb-6'>
        <Result
          status='error'
          title='Ops, parece que algo deu errado'
          subTitle={message}
        />

        <FullScreenProducts />
      </div>

      <Space>
        <Button
          loading={loading}
          type='primary'
          onClick={onClick}
          icon={<ReloadOutlined />}
        >
          Tentar novamente
        </Button>

        <DynamicColorButton
          colorClass='button-red'
          onClick={onExit}
          type='ghost'
          icon={<PoweroffOutlined />}
        >
          Sair do sistema
        </DynamicColorButton>

        {ProductModel.canShowSkipFullScreenError() &&
          <DynamicColorButton
            colorClass='button-green'
            onClick={onEnter}
            type='ghost'
            icon={<ArrowRightOutlined />}
          >
            Entrar no sistema
          </DynamicColorButton>
        }
      </Space>
    </div>
  )
}
