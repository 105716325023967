import React, { Fragment, useState } from 'react'
import { PoweroffOutlined, UserAddOutlined, UserDeleteOutlined, UserOutlined } from '@ant-design/icons'
import { useAuth, useResources } from 'hooks'
import { useHistory } from 'react-router'
import swal from 'utils/swal'
import { Dropdown, Menu } from 'antd'
import ProductsDropdown from 'components/SelectProductsSidebar/SelectProductsSidebar'
import ProductModel from 'ecp/models/ProductModel'
import { UsersModel } from 'ecp/models/UsersModel'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import useAuthLayout from 'hooks/useAuthLayout'
import NavbarSelectLevel from 'components/NavbarSelectLevel/NavbarSelectLevel'
import LetterAvatar from 'ecp/components/LetterAvatar/LetterAvatar'
import translate from 'utils/translate'
import useSelectedLevel from 'components/NavbarSelectLevel/hooks/useSelectedLevel'
import { ECPF } from 'routes/ecpf/constants'
import { SocketSingleton } from 'utils/socket'
import { BroadcastChannelRouter } from 'models/BroadcastRouterModel'

export function IconsMenu () {
  const user = useAuth()
  const history = useHistory()
  const resources = useResources()
  const { handleReset } = useAuthLayout()
  const { md } = useBreakpoint()
  const { onLevelSelect, isLoadingLevelChange } = useSelectedLevel()

  const [expandIconsMenu, setExpandIconsMenu] = useState(false)

  const menu = () => (
    <Menu className="dropdown-icons-menu">
      <section
        className="alignment-items-dropdown pb-3"
      >
        <LetterAvatar size='medium' filled name={user.name} />
        <div className='ml-2 w-75'>
          <div className='w-100'>
            <p className='bold text-ellipsis dropdown-icons-menu__user-name'>{user.name}</p>
          </div>
          <p className='color-gray font-size-12 font-700 '>{translate.level(user.level)}</p>
          <p className='color-gray font-size-12 font-500 pr-5'>{user?.convenio?.fantasyName}</p>
        </div>
      </section>

      {!md && ProductModel.hasEcpInProducts(resources.products || []) && (
        <section
          className="dropdown-icons-menu__product-dropdown alignment-items-dropdown pt-3 pb-3"
          onClick={() => {
            setExpandIconsMenu(false)
          }}
        >
          <ProductsDropdown />
        </section>
      )}

      {UsersModel.canSeeProfile(user.level) ? (
        <section
          className="alignment-items-dropdown pt-3 pb-3"
          onClick={() => {
            if (ProductModel.canAccessClientEcpfProfile(user.selectedProduct, user.level) && user?.cpf) {
              return history.push(`/auth${ECPF.CLIENT_DETAILS.path.replace(':cpf', user.cpf)}`)
            }

            history.push('/auth/profile')
            setExpandIconsMenu(false)
          }}
        >
          <UserOutlined className="icon mr-1" />
          <p>Perfil</p>
        </section>
      ) : null}

      {UsersModel.canSeeLevelSelect(user.levels) &&
        <section className="alignment-items-dropdown pt-3">
          <NavbarSelectLevel
            userId={user._id}
            authorizations={user.levels}
            onItemSelect={onLevelSelect}
            loading={isLoadingLevelChange}
          />
        </section>
      }

      <section
        className="alignment-items-dropdown pt-3"
        onClick={() => swal.confirmNegate({
          icon: 'warning',
          title: 'Atenção',
          text: 'Tem certeza que deseja sair?',
          confirm: () => {
            handleReset()

            const channel = new BroadcastChannelRouter()
            channel.postLogout()

            if (user.level === 'client') {
              const socket = new SocketSingleton()
              if (socket.io) socket.io.emit('leave-notification', user._id)
            }

            history.push('/')
            setExpandIconsMenu(false)
          }
        })}
      >
        <PoweroffOutlined className="icon mr-2" />
        <p>Sair</p>
      </section>
    </Menu>
  )

  return (
    <Fragment>
      {expandIconsMenu && (
        <div className="menu-icons-overlay" onClick={() => { setExpandIconsMenu(false) }} />
      )}

      <div className='navbar-buttons'>
        <Fragment>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            onVisibleChange={() => setExpandIconsMenu(prev => !prev)}
            visible={expandIconsMenu}
          >
            <section className="notification-relative-icon">
              {expandIconsMenu ? (
                <div className="expand-menu-icons" onClick={() => { setExpandIconsMenu(false) }}>
                  <UserDeleteOutlined className="icon" />
                </div>
              ) : (
                <div className="expand-menu-icons" onClick={() => { setExpandIconsMenu(false) }}>
                  <UserAddOutlined className="icon" />
                </div>
              )}
            </section>
          </Dropdown>
        </Fragment>
      </div>
    </Fragment>
  )
}
