import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { Button, Card, Col, Row, Select, Skeleton } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { resourcesGetProducts } from 'store/modules/resources/actions'
import { useDispatch } from 'react-redux'
import { useResources } from 'hooks'
import { ReactSVG } from 'react-svg'
import translate from 'utils/translate'
import { IProduct } from 'ecp/models/ProductModel'

function PricingProducts () {
  const history = useHistory()
  const dispatch = useDispatch()
  const resources = useResources()

  const [subProducts, setSubProducts] = useState<string[]>([])

  useLayoutEffect(() => {
    if (resources.products && resources.products.length < 1) {
      dispatch(resourcesGetProducts())
    }
  }, [])

  useEffect(() => {
    const createArray = new Array(resources.products.length)
    createArray.fill('')
    setSubProducts(createArray)
  }, [resources.products])

  return (
    <div>
      <h1 className="text-center mb-3">Escolha o produto que deseja alterar a precificação e clique em editar</h1>

      {resources.products && resources.products.length < 1 ? (
        <Row gutter={15} justify="center" className=" mt-3">
          <Col lg={4} md={8} sm={12} xs={24}>
            <Skeleton active className="card-style p-3" paragraph={{ rows: 3 }} />
          </Col>

          <Col lg={4} md={8} sm={12} xs={24}>
            <Skeleton active className="card-style p-3" paragraph={{ rows: 3 }} />
          </Col>

          <Col lg={4} md={8} sm={12} xs={24}>
            <Skeleton active className="card-style p-3" paragraph={{ rows: 3 }} />
          </Col>
        </Row>
      ) : (
        <Row gutter={15} justify="center">
          {resources.products.map((item: IProduct, index: number) => (
            <Fragment key={item._id}>
              {item.active && (
                <Col className="pb-3 text-center" key={item._id}>
                  <Card
                    style={{ minWidth: 200 }}
                    className="card-pricing-hover"
                  >
                    <ReactSVG
                      src={item.icon}
                      alt="Icone sobre cada passo do sistema uxline"
                      beforeInjection={(svg) => {
                        svg.classList.add('pricing-products-card')
                      }}
                    />
                    <Meta
                      className="mb-3 mt-1"
                      title={item.name}
                    />

                    <Select
                      className="w-100 mb-3"
                      placeholder="Escolha a categoria"
                      onChange={(value: string) => {
                        const finalProducts: any = subProducts
                        finalProducts[index] = value
                        setSubProducts(finalProducts)

                        const buttons: HTMLElement | null = document.querySelector(`#pricing-product-${item._id}`)
                        if (value !== '' && buttons) {
                          buttons.style.display = 'block'
                        }
                      }}
                    >
                      {item.sub.map(item => (
                        <Fragment key={item._id}>
                          {item.active && (
                            <Select.Option
                              value={item.name}
                              key={item._id}
                            >
                              {translate.subProducts(item.name)}
                            </Select.Option>
                          )}
                        </Fragment>
                      ))}
                    </Select>

                    <div id={`pricing-product-${item._id}`} style={{ display: 'none' }}>
                      <Button
                        type="primary"
                        disabled={!item.active}
                        onClick={() => history.push(`/auth/pricing/table/fisica/${item.slug}/${subProducts[index]}`)}
                        icon={<EditOutlined />}
                        className="w-100">
                        Editar PF
                      </Button>

                      <Button
                        type="primary"
                        disabled={!item.active}
                        onClick={() => history.push(`/auth/pricing/table/juridica/${item.slug}/${subProducts[index]}`)}
                        icon={<EditOutlined />}
                        className="w-100 mt-3">
                        Editar PJ
                      </Button>
                    </div>
                  </Card>
                </Col>
              )}
            </Fragment>
          )
          )}
        </Row>
      )}
    </div>
  )
}

export default PricingProducts
