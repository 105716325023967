import { Button, Form, Image, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Input } from 'components'
import ChangePasswordInputs from 'ecp/components/ChangePasswordInputs/ChangePasswordInputs'
import TokenValidationConfirm, { ITokenValidationConfirmProps } from 'ecpf/components/TokenValidator/components/TokenValidationConfirm/TokenValidationConfirm'
import { TokenService } from 'ecpf/services/tokenService'
import { TokenMethodEnum } from 'ecpf/components/TokenValidator/TokenValidator'
import { TokenValidationMessageTemplateType } from 'ecpf/models/TokenValidationModel'
import { ICheckTypistResponse } from 'ecpf/repositories/SigninECPFRepository'
import { OriginsService } from 'ecpf/services/originsService'
import { useResetPassword } from 'egi/app/ResetPassword/hooks/useResetPassword'
import { PASSWORD_HELP_TEXT } from 'egi/app/Signup/signupConstants'
import useAuthLayout from 'hooks/useAuthLayout'
import { UnauthLayout } from 'layouts'
import React, { ReactNode, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import imagesUrls from 'utils/imagesUrls'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { SigninService } from 'ecpf/services/signinService'

interface IForgotPasswordForm {
  password: string
  confirmPassword: string
}

const UnauthLayoutWithImage = ({ children }: { children: ReactNode }) => {
  return (
    <UnauthLayout>
      <div className="unauth-form-border">
        <div className='text-center'>
          <Image
            src={imagesUrls.rodobensLogoLight}
            preview={false}
            className='signin-image-logo'
            alt="rodobens"
          />
        </div>

        {children}
      </div>
    </UnauthLayout>
  )
}

export const TokenValidatorConfirmUnauth = ({ email, ...rest }: ITokenValidationConfirmProps & { email?: string }) => {
  return (
    <TokenValidationConfirm
      backButtonClassName='unauth-buttons-ghost'
      submitButtonClassName='unauth-buttons'
      description={
        <div className='unauth-reset-password-text'>
          <p className='mb-2'>Para manter sua conta segura, vamos verificar sua identidade.</p>
          <p>Adicione abaixo o código enviado para <b>{email}</b></p>
        </div>
      }
      {...rest}
    />
  )
}

const ForgotPasswordECPF = () => {
  const [form] = useForm()
  const [changePaswordForm] = useForm()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Partial<IForgotPasswordForm & { cpf: string }>>({})
  const [typistResponse, setTypistResponse] = useState<ICheckTypistResponse>()
  const [token, setToken] = useState('')
  const [step, setStep] = useState(1)

  const { handleSigninByAuth } = useAuthLayout()
  const { onSubmit: onSubmitResetPassword } = useResetPassword()
  const history = useHistory()

  const onIncrementStep = () => {
    setStep(prev => prev + 1)
  }

  const onDecrementStep = () => {
    setStep(prev => prev - 1)
  }

  const onSubmitToken = async (token: string, typistResponse?: ICheckTypistResponse) => {
    const tokenService = new TokenService()

    await tokenService.submit(token, typistResponse?.email || '', TokenMethodEnum.email)
    const originsService = new OriginsService()

    const updatedEmail = await originsService.updateTypistEmail(typistResponse?.cpf, typistResponse?.tokenId)

    onIncrementStep()
    setToken(token)

    return updatedEmail
  }

  const onSubmit = async (values: { cpf: string }) => {
    try {
      setLoading(true)

      const response = await onSubmitResetPassword({ cpf: values.cpf, withMFA: true, email: '' })

      setTypistResponse({
        ...response?.data?.data as ICheckTypistResponse,
        cpf: values.cpf
      })

      onIncrementStep()
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
      } else {
        swal.htmlBasic({
          text: err?.message,
          icon: 'warning',
          title: 'Atenção',
          customClass: {
            content: 'signin__basic-swam-html'
          }
        })

        setErrors({})
      }
    } finally {
      setLoading(false)
    }
  }

  const handleResendToken = async (contactInfo: string, method: TokenMethodEnum | null, messageTemplate: TokenValidationMessageTemplateType) => {
    const tokenService = new TokenService()
    return tokenService.resend(contactInfo, method, messageTemplate)
  }

  const onResetPassword = async (values: IForgotPasswordForm, token: string, email: string) => {
    try {
      setLoading(true)
      const signinService = new SigninService()
      const response = await signinService.forgotPassword({
        ...values,
        token,
        email
      })

      const auth = response.data.data
      if (auth?.token) {
        await handleSigninByAuth(auth)
      } else {
        history.push(UNAUTHS_PATHS.MAIN)
      }
    } catch (err) {
      message.error(err?.message)
    } finally {
      setLoading(false)
    }
  }

  if (step === 2) {
    return (
      <UnauthLayoutWithImage>
        <TokenValidatorConfirmUnauth
          email={typistResponse?.email}
          onSubmit={(token) => onSubmitToken(token, typistResponse)}
          onResendToken={
            () => handleResendToken(typistResponse?.email || '', TokenMethodEnum.email, TokenValidationMessageTemplateType.userValidation)
          }
          onGoBack={onDecrementStep}
        />
      </UnauthLayoutWithImage>
    )
  }

  if (step === 3) {
    return (
      <UnauthLayoutWithImage>
        <p className='unauth-reset-password-text'>
          {PASSWORD_HELP_TEXT}
        </p>

        <Form
          form={changePaswordForm}
          layout='vertical'
          onFinish={(values) => {
            onResetPassword(values, token, typistResponse?.email || '')
          }}
        >
          <ChangePasswordInputs
            errors={errors}
          />

          <Button
            loading={loading}
            type='primary'
            className='w-100 unauth-buttons'
            htmlType='submit'
          >
            Enviar
          </Button>
        </Form>
      </UnauthLayoutWithImage>
    )
  }

  return (
    <UnauthLayoutWithImage>
      <p className='unauth-reset-password-text'>
        Preencha o <b>CPF</b> de acesso para recuperar sua senha.
      </p>

      <Form
        onFinish={onSubmit}
        layout='vertical'
        className='unauth-form'
        form={form}
      >
        <Form.Item
          name='cpf'
          label={<label className='simulator-label ml-2'>CPF</label>}
          help={errors?.cpf}
          rules={[{ required: true, message: 'Por favor, digite um CPF.' }]}
          validateStatus={errors?.cpf && ('error')}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite o cpf'
            mask="cpf"
            error={errors.cpf}
          />
        </Form.Item>

        <Button
          type='primary'
          className='w-100 unauth-buttons'
          loading={loading}
          htmlType='submit'
        >
          Enviar
        </Button>

        <div className='text-center'>
          <Link
            to={UNAUTHS_PATHS.MAIN}
            className='underline'
          >
            Voltar ao login
          </Link>
        </div>
      </Form>
    </UnauthLayoutWithImage>
  )
}

export default ForgotPasswordECPF
