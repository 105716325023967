import React, { CSSProperties, Fragment, useEffect, useState } from 'react'
import { Layout, Menu, Badge } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { getRoutes } from 'routes'
import { IRoute, RouteType, RouteGroup } from './types'
import { trigger, sider, opacity } from './styles'
import { useDispatch } from 'react-redux'
import SubMenu from 'antd/lib/menu/SubMenu'
import { LoadingOutlined } from '@ant-design/icons'
import { useAuth, useChat } from 'hooks'
import { pendingChat } from 'store/modules/chat/actions'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useHistoryTable } from 'components/ServerTable/hooks/useServerTable'
import { SocketSingleton } from 'utils/socket'
const { Sider } = Layout

const menyStyle: CSSProperties = {
  paddingTop: 40,
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'relative',
  maxHeight: '100%'
}

const Sidebar = () => {
  const auth = useAuth()
  const chat = useChat()
  const dispatch = useDispatch()
  const historyTable = useHistoryTable()
  const location = useLocation()

  const [routes, setRoutes] = useState<RouteType[]>([])
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedWidth, setCollapsedWidth] = useState(80)
  const [badgeCount, setBadgeCount] = useState(0)
  const { sm: isSmall } = useBreakpoint()

  useEffect(() => {
    setBadgeCount(chat.chatPending)
  }, [chat.chatPending])

  const renderRoute = (route: IRoute | RouteGroup): any => {
    if ((route as IRoute).path) {
      const routeItem = (route as IRoute)
      const disabledText = routeItem.meta.disabled === true ? 'ant-menu-item-disabled' : ''

      const { pathname, search: metaSearch, ...locationMeta } = routeItem?.meta?.location || {}
      const path = pathname || routeItem.path
      const isCurrentPath = location.pathname.replace('/auth', '') === path
      const search = isCurrentPath ? location.search : metaSearch || ''

      const linkTo = {
        ...locationMeta,
        pathname: `/auth${path}`,
        search
      }

      return (
        <Menu.Item
          onClick={() => historyTable.setFiltersValues(routeItem.path)}
          disabled={routeItem.meta.disabled}
          key={routeItem.path}
          icon={route.icon && <route.icon/>}
        >
          <Link
            to={linkTo}
          >
            {routeItem.name === 'Chamados' ? (
              <Badge count={badgeCount} offset={[12, 0]} showZero>
                <span className={disabledText}>{routeItem.name}</span>
              </Badge>
            ) : (
              <Fragment>
                {routeItem.name}
              </Fragment>
            )}
          </Link>
        </Menu.Item>
      )
    } else {
      const routeGroup = (route as RouteGroup)
      return (
        <SubMenu
          icon={<route.icon /> || <LoadingOutlined />}
          title={routeGroup.name}
        >
          {routeGroup.routes.map(route => renderRoute(route))}
        </SubMenu>
      )
    }
  }

  function handleCollapse () {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    if (auth.level && auth.selectedProduct?._id) {
      const baseRoutes = getRoutes(auth.selectedProduct?.slug, auth)
      setRoutes(baseRoutes)
    }

    function socketEmit () {
      const socket = new SocketSingleton()
      if (socket.io) {
        socket.io.on('newMessage', () => {
          dispatch(pendingChat())
        })
      }
    }

    socketEmit()
  }, [
    auth.selectedProduct?._id,
    auth?._id
  ])

  const siderStyle = sider(Boolean(!isSmall))

  return (
    <div
      style={(!isSmall && !collapsed) ? opacity : undefined}
      className='sidebar-size'
    >
      <div
        style={(!isSmall && !collapsed) ? opacity : undefined}
        onClick={() => handleCollapse() }
      />

      <Sider
        collapsible
        breakpoint="sm"
        style={{ ...siderStyle, zIndex: 40 }}
        collapsedWidth={collapsedWidth}
        zeroWidthTriggerStyle={trigger}
        collapsed={collapsed}
        onCollapse={() => handleCollapse()}
        className='sidebar-size'
        onBreakpoint={broken => {
          broken
            ? setCollapsedWidth(0)
            : setCollapsedWidth(80)
        }}
      >
        <Menu
          className='menu-sidebar'
          style={menyStyle}
          mode="inline"
          siderCollapsed={collapsed}
          selectedKeys={[location.pathname.replace('/auth', '')]}
          onClick={() => {
            !isSmall && setCollapsed(!collapsed)
          }}
        >
          {routes.map(route => (
            (!(route as IRoute)?.meta?.hidden &&
              renderRoute(route))
          ))}
        </Menu>
      </Sider>
    </div>
  )
}

export default Sidebar
