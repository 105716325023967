import UsersRepository from 'ecp/repositories/UsersRepository'
import { useDispatch, useSelector } from 'react-redux'
import { persistor } from 'store'
import { authActions } from 'store/modules/auth/authActions'
import { progressQueuestActions } from 'store/modules/progressQueue/progressQueueActions'
import { layoutActions } from 'store/modules/layout/layoutActions'
import { _credentialStatus } from 'store/modules/layout/layoutActionTypes'
import { resourcesReset, resourcesSetProducts } from 'store/modules/resources/actions'
import { RootState } from 'store/modules/rootReducer'
import { resetRouteConfig } from 'store/modules/system/actions'
import { tablesResetAll } from 'store/modules/tables/actions'
import { clearStorage } from 'utils/localStorage'
import TermsRepository from 'ecp/repositories/TermsRepository'
import { _userLevel } from 'ecp/models/UsersModel'
import { AxiosRequestConfig } from 'axios'
import { IAuth } from 'egi/types'
import { _productSlugs } from 'ecp/models/ProductModel'
import useSelectedLevel from 'components/NavbarSelectLevel/hooks/useSelectedLevel'
import { formatUserFactory } from 'store/modules/auth/authSagas'
import { SocketSingleton } from 'utils/socket'
import { BroadcastChannelRouter } from 'models/BroadcastRouterModel'

export class ProductNotFound extends Error {
  name = 'PRODUCT_NOT_FOUND';
  message = 'Nenhum produto encontrado!';
}

export default function useAuthLayout () {
  const dispatch = useDispatch()
  const layout = useSelector((state: RootState) => state.layout)
  const { getProducts, goTofirstRoute } = useSelectedLevel()

  const handleLoading = (loading: boolean) => {
    dispatch(layoutActions.setLoading({ loading }))
  }

  const handleError = (error: string) => {
    dispatch(layoutActions.setError({ error }))
  }

  const handleStatus = (status: _credentialStatus) => {
    dispatch(layoutActions.setCredential({ status }))
  }

  const handleReset = () => {
    const socket = new SocketSingleton()
    socket.disconnect()

    dispatch(layoutActions.reset())
    dispatch(authActions.resetAuth())
    dispatch(tablesResetAll())
    dispatch(resetRouteConfig())
    dispatch(resourcesReset())
    dispatch(progressQueuestActions.reset())
    dispatch(authActions.resetLevels())

    clearStorage()
    persistor.purge()
  }

  const handleLGPDTermSignature = async () => {
    const abortController = new AbortController()
    try {
      const response = await TermsRepository.getTerms({ signal: abortController.signal })
      const terms = response.data.data
      if (!terms) return handleLoading(false)
      dispatch(authActions.setLGPDInfo(terms))
      handleError('')
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (err?.message) handleError(err.message)
        handleLoading(false)
      }
    }
  }

  const isOnidataCredentialWorking = async () => {
    try {
      const response = await UsersRepository.isOnidataCredentialWorking()
      const status = response.data.data?.status
      if (!status) throw new Error('Falha ao encontrar status da credencial no Canal Rodobens')
      dispatch(layoutActions.setCredential({ status }))
      handleError('')
    } catch (err) {
      if (err.message) handleError(err.message)
      handleLoading(false)
    }
  }

  const handleFetchUserInfo = async (userLevel: _userLevel) => {
    handleLoading(true)
    if (userLevel === 'client') await handleLGPDTermSignature()
    await isOnidataCredentialWorking()
    handleLoading(false)
  }

  const handleSigninByAuth = async (auth: IAuth) => {
    const requestConfig: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${auth.token}` }
    }

    const { firstProduct, products } = await getProducts({ requestConfig, user: auth })
    if (!firstProduct || !firstProduct._id) {
      handleReset()
      throw new ProductNotFound()
    }

    dispatch(resourcesSetProducts(products || []))

    const channel = new BroadcastChannelRouter()
    channel.postLogin()

    dispatch(authActions.signinSucceed(formatUserFactory(auth)))

    goTofirstRoute(firstProduct.slug as _productSlugs, auth)
  }

  return {
    layout,
    isOnidataCredentialWorking,
    handleLoading,
    handleError,
    handleReset,
    handleStatus,
    handleLGPDTermSignature,
    handleFetchUserInfo,
    handleSigninByAuth
  }
}
