import { IResponseBase, Repository } from './Repository'
import api from 'services/api'
import { AxiosRequestConfig } from 'axios'
import { IEcpForgotPasswordOnidata, IResetWithToken } from 'ecp/app/SignupForm/signupFormInterfaces'
import { ISignupClientConfirmRequest, ISignupClientLGPDRequest, ISignupClientPasswordRequest, ISignupClientPasswordResponse, ISignupClientRequestLGPDRequest, ISignupClientRequestLGPDResponse, ISignupClientRequestPhoneToken } from 'ecp/app/SignUpClient/signUpClientInterfaces'
import { ICLientCheckLink, IFirstAccesClientEmailCpFPassword, IFirstAccesCorbanPassword, IFirstAccessConfirm, IFirstAccessConfirmEmail, IFirstAccessSendEmail, IFirstAccessSendPhone, IRegistrationStatusResponse } from 'ecp/app/FirstAccess/firstAccesInterfaces'
import { _productSlugs } from 'ecp/models/ProductModel'
import { _userLevel } from 'ecp/models/UsersModel'
import { ILGPDAcessSendEmail } from 'ecp/app/LGPDAcess/LGPDAccessInterfaces'
import { IAuth } from 'egi/types'

export type SignInCredentials = {
  identifier: string
  password?: string
  userId?: string
  authenticatedOIDC?: boolean
  access_token?: string
  isEcpf?: boolean
  signinMethod?: string
}

export type ChangePassword = {
  token: string
  password: string
  confirmPassword: string
}

export type AuthorizationsUser = {
  _id: string
  level: _userLevel
  products: Array<_productSlugs>
  name: string
  covenant?: {
    fantasyName?: string
    _id?: string
  }
}

export interface IFirstAccesCorbanPhoneRequest {
  telefone_celular: string
  tipo: string
  cpf: string
  token: string
}

export interface IFirstAccesCorbanPhoneResponse {
  email: string
  onidataToken: string
}
class LoginRepository extends Repository<{}> {
  private preSignupPath = this.path + '/pre-signup'

  async authorizations (data: SignInCredentials, config?: AxiosRequestConfig) {
    return Repository.handle<{
      users: Array<AuthorizationsUser>
    }>(() =>
      this.api.post(`${this.path}/authorizations`, data, config)
    )
  }

  async login (credentials: SignInCredentials, config?: AxiosRequestConfig) {
    return Repository.handle<IResponseBase>(() =>
      this.api.post(this.path, credentials, config)
    )
  }

  async changePassword (credentials: ChangePassword, config?: AxiosRequestConfig) {
    return Repository.handle<IAuth>(() =>
      this.api.post(`${this.path}/change/password`, credentials, config)
    )
  }

  async forgotPassword (data: { email_ou_cpf: string }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/forgot-password/token`, data, config)
    )
  }

  async resetWithToken (data: IResetWithToken, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/signup`, data, config)
    )
  }

  async resetWithTokenOnidata (data: IEcpForgotPasswordOnidata, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.put(`${this.path}/forgot-password`, data, config)
    )
  }

  async reset (email: string) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/token`, { email })
    )
  }

  async clientSignup (data: ISignupClientPasswordRequest, config?: AxiosRequestConfig) {
    return Repository.handle<ISignupClientPasswordResponse>(() =>
      this.api.post(`${this.preSignupPath}`, data, config)
    )
  }

  async requestLGPD (data: ISignupClientRequestLGPDRequest) {
    return Repository.handle<ISignupClientRequestLGPDResponse>(() =>
      this.api.get(`${this.preSignupPath}/terms`, data)
    )
  }

  async confirmLGPD (data: ISignupClientLGPDRequest, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.preSignupPath}/terms`, data, config)
    )
  }

  async confirm (data: ISignupClientConfirmRequest, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.preSignupPath}/confirm`, data, config)
    )
  }

  async requestPhoneToken (data: ISignupClientRequestPhoneToken, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.preSignupPath}/send-sms`, data, config)
    )
  }

  async registrationStatus (data: ICLientCheckLink, config?: AxiosRequestConfig) {
    return Repository.handle<IRegistrationStatusResponse>(() =>
      this.api.post(`${this.path}/registration-status`, data, config)
    )
  }

  async sendEmailTolken (data: IFirstAccessSendEmail, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.preSignupPath}/send-email-token`, data, config)
    )
  }

  async confirmEmail (data: IFirstAccessConfirmEmail, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.preSignupPath}/email/confirm`, data, config)
    )
  }

  async confirmSms (data: IFirstAccessConfirm, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.preSignupPath}/sms/confirm`, data, config)
    )
  }

  async sendPhoneToken (data: IFirstAccessSendPhone, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.preSignupPath}/send-sms`, data, config)
    )
  }

  async firstAcces (data: IFirstAccesClientEmailCpFPassword, config?: AxiosRequestConfig) {
    return Repository.handle<ISignupClientPasswordResponse>(() =>
      this.api.post(`${this.path}/first-access`, data, config)
    )
  }

  async sendFirstAccessPhone (data: IFirstAccesCorbanPhoneRequest, config?: AxiosRequestConfig) {
    return Repository.handle<{ routeResponse: IFirstAccesCorbanPhoneResponse }>(() =>
      this.api.patch(`${this.preSignupPath}/unauth/send-sms`, data, config)
    )
  }

  async firstAccessPassword (data: IFirstAccesCorbanPassword, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.preSignupPath}/password`, data, config)
    )
  }

  async lgpdSendEmail (data: ILGPDAcessSendEmail, config?: AxiosRequestConfig) {
    return Repository.handle<ISignupClientPasswordResponse>(() =>
      this.api.post(`${this.path}/send-token-lgpd`, data, config)
    )
  }

  async lgpdSignUp (data: FormData, config?: AxiosRequestConfig) {
    return Repository.handle<ISignupClientPasswordResponse>(() =>
      this.api.post(`${this.path}/validate-token-lgpd`, data, config)
    )
  }
}

export default new LoginRepository({ api, path: '/ecp/login' })
