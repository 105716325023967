import { isDevelopment, isLocal } from 'utils/globals'

export enum SigninLayout {
  default = 'default',
  ecpf = 'ecpf',
  ecp = 'ecp'
}

export const SigninModel = {
  chooseLayout: (): SigninLayout => {
    const hostname = window.location.hostname
    const validHostNames = ['localhost', 'hml.ecp.rodobens.agxsoftware.com']

    if (validHostNames.includes(hostname)) {
      return SigninLayout.ecpf
    }

    return SigninLayout.default
  },

  canSwitchLayouts (secretCount: number): boolean {
    if (isDevelopment && secretCount > 15) return true
    return isLocal
  }
}
