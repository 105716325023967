import { AxiosRequestConfig } from 'axios'
import { ISearch } from 'components/SeverTable/types'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

interface IReport {
  csv: string
}

interface IQueueProgressRequest {
  link: string
  percentage: 100
  type: string
  error?: string
  status?: 'finish' | 'error'
}

class ReportsRepository extends Repository<IReport> {
  async sendRequest (filters: ISearch, path: string) {
    return Repository.handle<IReport>(() =>
      this.api.post(this.path + path, filters)
    )
  }

  async sendQueue (filters: ISearch, path: string) {
    return Repository.handle<{requestId: string}>(() =>
      this.api.post(this.path + path, filters)
    )
  }

  async queueProgress (id: string, config: AxiosRequestConfig): Promise<IResponseBase<{queueCheck: IQueueProgressRequest}>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/check/${id}`, config)
    )
  }
}

export default new ReportsRepository({ api, path: 'ecp/reports' })
