import { UploadFile } from 'antd/lib/upload/interface'
import { AxiosRequestConfig } from 'axios'
import { IProposalHistory, ISendStepBody, ISendStepQuery, ISendStepResponse } from 'ecp/app/Proposals/proposalInterfaces'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class DynamicProposalECPFRepository extends Repository {
  async sendStep <IBody> ({ departmentId, proposalId, stepId }: ISendStepQuery, body: ISendStepBody<IBody>, config?: AxiosRequestConfig): Promise<IResponseBase<ISendStepResponse>> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/${proposalId}/department/${departmentId}/step/${stepId}`, body, config)
    )
  }

  async listFiles ({ departmentId, proposalId, stepId }: ISendStepQuery, config?: AxiosRequestConfig) {
    return Repository.handle<{ files: Array<{ _id: string, location: string }>}>(() =>
      this.api.get(`${this.path}/${proposalId}/department/${departmentId}/step/${stepId}/file`, config)
    )
  }

  async deleteFile ({ departmentId, proposalId, stepId, fileId }: ISendStepQuery & { fileId: string }, config?: AxiosRequestConfig) {
    return Repository.handle<{ _id: string }>(() =>
      this.api.delete(`${this.path}/${proposalId}/department/${departmentId}/step/${stepId}/file/${fileId}`, config)
    )
  }

  async fetchStepHistory (proposalId: string, departmentId: string, stepId: string, config?: AxiosRequestConfig): Promise<IResponseBase<IProposalHistory.Response>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${proposalId}/department/${departmentId}/step/${stepId}/history`, config)
    )
  }

  async generateUploadDocumentsLink (proposalId: string, body: {departmentId: string, stepId: string, clientName?: string}, config?: AxiosRequestConfig): Promise<IResponseBase<{link: string}>> {
    return Repository.handle<{link: string}>(() =>
      this.api.post(`${this.path}/${proposalId}/link`, body, config)
    )
  }

  async uploadFile ({ departmentId, proposalId, stepId }: ISendStepQuery, body: { file: UploadFile }, config?: AxiosRequestConfig) {
    return Repository.handle<{ _id: string }>(() =>
      this.api.post(`${this.path}/${proposalId}/department/${departmentId}/step/${stepId}/file`, body, config)
    )
  }

  async getProposalStatus (config?: AxiosRequestConfig): Promise<IResponseBase<{ status: string[] }>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/resources/step/status`, config)
    )
  }
}

export default new DynamicProposalECPFRepository({ api, path: 'dynamic-proposal' })
