import { IProduct } from 'ecp/models/ProductModel'
import { IFrameModels } from './types'

export const resourcesGetProducts = (callback?: Function) => ({
  type: 'RESOURCES_PRODUCTS_REQUESTED',
  payload: {
    callback
  }
})

export const resourcesSetProducts = (products: IProduct[]) => ({
  type: 'RESOURCES_PRODUCTS_SUCCEED',
  payload: { products }
})

export const resourcesGetAreas = () => ({
  type: 'RESOURCES_AREAS_REQUESTED'
})

export const resourcesGetProviders = ({ areaName }: { areaName: 'juridico' | 'engenharia' | '' }) => ({
  type: 'RESOURCES_PROVIDERS_REQUESTED',
  payload: {
    areaName
  }
})

export const resourcesGetDeclines = ({ productId }: {productId: string}) => ({
  type: 'RESOURCES_DECLINES_REQUESTED',
  payload: {
    productId
  }
})

export const resourcesGetStates = () => ({
  type: 'RESOURCES_STATES_REQUESTED'
})

export const resourcesGetStatesIBGE = () => ({
  type: 'RESOURCES_STATES_IBGE_REQUESTED'
})

export const resourcesGetBanks = () => ({
  type: 'RESOURCES_BANKS_REQUESTED'
})

export const resourcesGetEducations = () => ({
  type: 'RESOURCES_EDUCATIONS_REQUESTED'
})

export const resourcesGetConsultants = () => ({
  type: 'RESOURCES_CONSULTANTS_REQUESTED'
})
export const resourcesGetBusinessManagers = () => ({
  type: 'RESOURCES_BUSINESS_MANAGERS_REQUESTED'
})

export const resourcesGetSupport = () => ({
  type: 'RESOURCES_SUPPORT_REQUESTED'
})

export const resourcesGetTelemarketing = () => ({
  type: 'RESOURCES_TELEMARKETING_REQUESTED'
})

export const resourcesGetCountries = () => ({
  type: 'RESOURCES_COUNTRIES_REQUESTED'
})

export const resourcesGetLegalNature = () => ({
  type: 'RESOURCES_LEGAL_NATURE_REQUESTED'
})

export const resourcesGetEconomicActivity = () => ({
  type: 'RESOURCES_ECONOMIC_ACTIVITY_REQUESTED'
})

export const resourcesGetGoals = () => ({
  type: 'RESOURCES_GOALS_REQUESTED'
})

export const resourcesGetRefusedReasons = () => ({
  type: 'RESOURCES_REFUSED_REASONS_REQUESTED'
})

export const resourcesGetAreaNames = () => ({
  type: 'RESOURCES_AREA_NAMES_REQUESTED'
})

export const resourcesReset = () => ({
  type: 'RESOURCES_RESET'
})

export const resourcesGetQualifications = ({ productId }: {productId: string}) => ({
  type: 'RESOURCES_MINUTA_MOLD_REQUESTED',
  payload: {
    productId
  }
})

export const setResourcesQualifications = ({ minutaMold }: { minutaMold: IFrameModels[] }) => ({
  type: 'SET_RESOURCES_MINUTA_MOLD_REQUESTED',
  payload: minutaMold
})
