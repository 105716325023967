import React, { useEffect } from 'react'
import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import clientRoutes from './levels/client'
import masterRoutes from './levels/master'
import commissionRoutes from './levels/commission'
import promoterRoutes from './levels/promoter'
import backOfficeRoutes from './levels/backOffice'
import commercialRoutes from './levels/commercial'
import pricingRoutes from './levels/pricing'
import adminRoutes from './levels/admin'
import consultantsRoutes from './levels/consultant'
import providerRoutes from './levels/provider'
import sellerRoutes from './levels/seller'
import auditorRoutes from './levels/auditor'
import agxRoutes from './levels/agx'
import UnauthRouter from '../layouts/UnauthLayout/UnauthRouter'
import { useDispatch } from 'react-redux'
import { setSystemConfig } from '../store/modules/system/actions'
import { useAuth, useHostConfig } from '../hooks'
import { RouteType } from '../components/Sidebar/types'
import { UNAUTHS_ROUTES } from './unauthRoutes'
import { ecpRoutes } from './ecp/ecpRoutes'
import { getEcpfRoutes, getEcpRoutes } from './functions'
import IAuth from 'egi/types/IAuth'
import EcpSidebar from 'ecp/models/EcpSidebar'
import Signin from 'egi/app/Signin/Signin'
import { _productSlugs } from 'ecp/models/ProductModel'
import { authActions } from 'store/modules/auth/authActions'
import AuthRouter from '../layouts/Auth/AuthRouter'
import EcpfSidebar from 'ecp/models/EcpfSidebar'
import { ecpfRoutes } from './ecpf/ecpfRoutes'
import useAuthLayout from 'hooks/useAuthLayout'
import { BroadcastChannelRouter } from 'models/BroadcastRouterModel'

function getRoutes (productSlug: _productSlugs = 'home-equity', auth: IAuth): Array<RouteType> {
  const { level } = auth
  if (productSlug === 'ecp') {
    const levelRoute = getEcpRoutes(level, auth)
    if (!levelRoute) return []

    const sidebarRoutes = new EcpSidebar({ userRoutes: levelRoute })
    return sidebarRoutes.getUserRoutes(ecpRoutes)
  }

  if (productSlug === 'ecp-funcao') {
    const levelRoute = getEcpfRoutes(level, auth)
    if (!levelRoute) return []

    const sidebarRoutes = new EcpfSidebar({ userRoutes: levelRoute })
    return sidebarRoutes.getUserRoutes(ecpfRoutes)
  }

  switch (auth.level) {
    case 'client':
      return clientRoutes
    case 'master':
      return masterRoutes(auth)
    case 'backoffice':
      return backOfficeRoutes(auth)
    case 'commission':
      return commissionRoutes
    case 'commercial':
      return commercialRoutes(auth)
    case 'promoter':
      return promoterRoutes(auth)
    case 'pricing':
      return pricingRoutes
    case 'administrator':
      return adminRoutes(auth.areaName)
    case 'consultant':
      return consultantsRoutes
    case 'provider':
      return providerRoutes
    case 'seller':
      return sellerRoutes
    case 'auditor':
      return auditorRoutes()
    case 'agx':
      return agxRoutes
    case 'RH':
      return []
    default:
      return []
  }
}

function Routes () {
  const auth = useAuth()
  const dispatch = useDispatch()
  const { handleReset } = useAuthLayout()

  const path = window.location.hostname
  const corban = useHostConfig()

  useEffect(() => {
    dispatch(setSystemConfig({ corban }))
  }, [path])

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (auth.isAuth) {
      interval = setInterval(() => {
        dispatch(authActions.canRefreshToken())
      }, 60000)
    }

    return () => clearInterval(interval)
  }, [
    auth.isAuth
  ])

  useEffect(() => {
    const channel = new BroadcastChannelRouter()
    channel.onmessage = (msg) => {
      if (msg.data === BroadcastChannelRouter.login) {
        const isAtRoot = document.location.pathname === '/'

        if (isAtRoot) {
          window.location.reload()
        }
      }

      if (msg.data === BroadcastChannelRouter.logout) {
        handleReset()
      }
    }

    return () => {
      channel.close()
    }
  }, [])

  return (
    <Router>
      <Switch>
        <UnauthRouter exact path='/' component={Signin} />

        {UNAUTHS_ROUTES.map(item => (
          <UnauthRouter
            key={item.path}
            exact
            path={item.path}
            component={item.component}
          />
        ))}

        <AuthRouter
          authed={auth.isAuth}
          path='/auth'
        />

        <Route path='*'><Redirect to='/' /></Route>
      </Switch>
    </Router>
  )
}

export {
  getRoutes
}

export default Routes
