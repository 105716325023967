import { Repository } from './Repository'
import api from 'services/api'
import { AxiosRequestConfig } from 'axios'

interface IBackofficeInvite {
  areaName: string
}

interface IValidateOnidata {
  cpf?: string
  email?: string
}

class BackofficeEcpRepository extends Repository<IBackofficeInvite> {
  async submitInviteRequest (data: IBackofficeInvite, config?: AxiosRequestConfig) {
    return Repository.handle<{link: string}>(() =>
      this.api.post(this.path, data, config)
    )
  }

  async validateOnidata ({ cpf, email } : IValidateOnidata): Promise<any> {
    return Repository.handle(() =>
      this.api.post('/ecp/unauth/backoffice/validate/onidata', { cpf, email })
    )
  }
}

export default new BackofficeEcpRepository({ api, path: '/ecp/backoffice' })
