import { IAuth } from 'egi/types'
import { EcpfRoutes } from 'routes/ecpf/ecpfRoutes'
import { UserEcpSidebar } from './EcpSidebar'

interface IEcpfSidebar {
  userRoutes: UserEcpSidebar
}

export default class EcpfSidebar {
  protected userRoutes: UserEcpSidebar

  constructor ({ userRoutes }: IEcpfSidebar) {
    this.userRoutes = userRoutes
  }

  getUserRoutes (routes: EcpfRoutes) {
    return this.userRoutes.filterRoutes(routes)
  }
}

export class CorbanRoutesEcpf extends UserEcpSidebar {
  filterRoutes (routes: EcpfRoutes) {
    return [
      routes.ecpfProposalsList,
      routes.ecpfFindUserNewContract,
      routes.ecpfProposalFlow,
      routes.ecpfFindUserRenegotiation,
      routes.ecpfSelectContractsRenegotiation,
      routes.ecpfFindUserRefinancing,
      routes.ecpfSelectContractsRefinancing,
      routes.ecpfPortability,
      routes.ecpfFindUserPortability,
      routes.ecpfOperatorList,
      routes.ecpfProposalDetails,
      routes.ecpfEmployerList,
      this.hideRoute(routes.ecpfOrganizationList)
    ]
  }
}

export class OperatorRoutesEcpf extends UserEcpSidebar {
  filterRoutes (routes: EcpfRoutes) {
    return [
      routes.ecpfProposalsList,
      routes.ecpfProposalDetails,
      routes.ecpfFindUserNewContract,
      routes.ecpfProposalFlow,
      routes.ecpfFindUserRenegotiation,
      routes.ecpfSelectContractsRenegotiation,
      routes.ecpfFindUserRefinancing,
      routes.ecpfSelectContractsRefinancing,
      routes.ecpfPortability,
      routes.ecpfFindUserPortability,
      routes.ecpfEmployerList,
      this.hideRoute(routes.ecpfOrganizationList)
    ]
  }
}

export class MasterRoutesEcpf extends UserEcpSidebar {
  filterRoutes (routes: EcpfRoutes) {
    return [
      routes.ecpfProposalsList,
      routes.ecpfRegionalList,
      routes.ecpfProposalDetails,
      routes.ecpfMainOfficeList,
      routes.ecpfPromoterList,
      routes.ecpfEmployerList,
      routes.ecpfOrganizationList,
      routes.ecpfOperatorList,
      routes.ecpfClientDetails,
      routes.ecpfClientForm,
      routes.ecpfConfiguration,
      routes.ecpfConfigurationInsurance
    ]
  }
}

export class ClientRoutesEcpf extends UserEcpSidebar {
  filterRoutes (routes: EcpfRoutes) {
    return [
      routes.ecpfClientInitial,
      routes.ecpfProposalsList,
      routes.ecpfProposalFlow,
      routes.ecpfProposalDetails,
      routes.ecpfClientDetails,
      routes.ecpfClientForm,
      routes.ecpfFindUserNewContract,
      routes.ecpfFindUserRenegotiation,
      routes.ecpfSelectContractsRenegotiation,
      routes.ecpfFindUserRefinancing,
      routes.ecpfSelectContractsRefinancing,
      routes.ecpfPortability,
      routes.ecpfFindUserPortability
    ]
  }
}

export class AdminRoutesEcpf extends UserEcpSidebar {
  protected auth: IAuth

  constructor (auth: IAuth) {
    super()

    this.auth = auth
  }

  juridicoRoutes (routes: EcpfRoutes) {
    const currentSearch = routes.ecpfProposalsList?.meta?.location?.search ?? ''

    return [
      this.changeSearch(routes.ecpfProposalsList, currentSearch + '&situation=43'),
      routes.ecpfProposalDetails,
      routes.ecpfClientDetails,
      routes.ecpfClientForm
    ]
  }

  filterRoutes (routes: EcpfRoutes) {
    if (this.auth.areaName === 'juridico') {
      return this.juridicoRoutes(routes)
    }

    return []
  }
}

export class BackofficeRoutesEcpf extends UserEcpSidebar {
  protected auth: IAuth

  constructor (auth: IAuth) {
    super()

    this.auth = auth
  }

  juridicoRoutes (routes: EcpfRoutes) {
    const currentSearch = routes.ecpfProposalsList?.meta?.location?.search ?? ''

    return [
      this.changeSearch(routes.ecpfProposalsList, currentSearch + '&situation=43'),
      routes.ecpfProposalDetails,
      routes.ecpfClientDetails,
      routes.ecpfClientForm
    ]
  }

  filterRoutes (routes: EcpfRoutes) {
    if (this.auth.areaName === 'juridico') {
      return this.juridicoRoutes(routes)
    }

    return []
  }
}
