import { call, put, all, takeLatest, select, takeEvery } from 'redux-saga/effects'
import time, { timeAsDayjs } from 'utils/time'
import { user } from 'egi/consumers'
import swal from 'utils/swal'
import LoginRepository, { SignInCredentials } from 'ecp/repositories/LoginRepository'
import { IAuthActions, ICanRefreshToken } from './authTypes'
import { authActions, AuthActionTypes } from './authActions'
import { setToken } from 'utils/localStorage'
import { IAuth } from 'egi/types'
import { SocketSingleton } from 'utils/socket'
import LoginRepositoryEgi from 'egi/repositories/LoginRepositoryEgi'

async function loginAttempt (credentials: SignInCredentials) {
  if (credentials.signinMethod === 'ecp') return LoginRepository.login(credentials)

  return LoginRepositoryEgi.login(credentials)
}

const generateExpiresInSeconds = (secondsAmount: number) => {
  return time(timeAsDayjs().add(secondsAmount, 'second'), { applyTimezone: false })
}

export const formatUserFactory = (user: IAuth) => {
  const expiresAt = generateExpiresInSeconds(user?.expiresIn ?? 0)

  setToken(user?.token ?? '')

  const socket = new SocketSingleton()
  if (socket.io) {
    socket.io.io.opts.query = { bearer: user?.token }
    socket.io.emit('login', user?.token)
  }

  return {
    ...user,
    expiresAt,
    id: user._id,
    followUpProposal: false
  }
}

function * login (action: IAuthActions.SigninRequest) {
  const {
    identifier,
    password,
    userId,
    callback,
    access_token,
    authenticatedOIDC,
    signinMethod
  } = action?.payload || {}

  try {
    const loggedUser = yield call(
      loginAttempt, {
        identifier,
        password,
        userId,
        access_token,
        authenticatedOIDC,
        signinMethod
      }
    )

    const user = loggedUser.data.data?.signin

    const proposal = user?.proposal

    if (proposal) {
      yield put({
        type: 'PROPOSAL_STATE_FETCH_SUCCEEDED',
        payload: { id: proposal }
      })
    }

    callback?.(user, null)
  } catch (err) {
    callback?.(null, err)
  }
}

function * canRefresh (): ICanRefreshToken {
  const selector = yield select()
  const user = selector.auth

  try {
    const expires = timeAsDayjs(user.expiresAt, { applyTimezone: false })
    const diffInMinutes: number = Math.abs(expires.diff(timeAsDayjs(), 'minute'))

    if (diffInMinutes < 5) {
      if (user.refreshToken) {
        const refreshAction = authActions.refresh(user.refreshToken)
        yield put(refreshAction)
      }
    }
  } catch (err) {
    console.warn(err)
  }
}

function * refresh (action: IAuthActions.Refresh) {
  const { refreshToken } = action.payload

  try {
    const response = yield call(
      user.refreshToken,
      refreshToken
    )

    const { token, expiresIn } = response.data || {}
    const expiresAt = generateExpiresInSeconds(expiresIn)

    const refreshTokenAction = authActions.refreshTokenSucceed(token, expiresAt)
    yield put(refreshTokenAction)

    yield setToken(token)
  } catch (err) {
    swal.basic({ text: err.message, icon: 'warning', title: 'Atenção' })
  }
}

function * setUserData (action: IAuthActions.SetUserData) {
  const { user, callback } = action.payload
  const expiresAt = generateExpiresInSeconds(user.expiresIn as number)
  if (user.token) yield setToken(user.token)

  const socket = new SocketSingleton()
  if (socket.io) {
    socket.io.io.opts.query = { bearer: user.token }
    socket.io.emit('login', user.token)
  }

  const proposalId = user.proposalId

  if (proposalId) {
    yield put({
      type: 'PROPOSAL_STATE_FETCH_SUCCEEDED',
      payload: { id: proposalId }
    })
  }

  const signinSucceed = authActions.signinSucceed({
    ...user,
    expiresAt: String(expiresAt)
  })

  yield put(signinSucceed)

  callback?.(user.level, null)
}

export default all([
  takeEvery(AuthActionTypes.SIGNIN_REQUEST, login),
  takeLatest(AuthActionTypes.REFRESH_TOKEN_REQUESTED, refresh),
  takeLatest(AuthActionTypes.CAN_REFRESH_TOKEN, canRefresh),
  takeLatest(AuthActionTypes.SET_USER_DATA_REQUESTED, setUserData)
])
