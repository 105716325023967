import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import { IList } from '@Jeiel-Oliveira/agx-chat-web/dist/esm/types'
import { Button, message, Skeleton } from 'antd'
import LetterAvatar from 'ecp/components/LetterAvatar/LetterAvatar'
import ChatModel from 'egi/models/ChatModel'
import ChatRepository from 'egi/repositories/ChatRepository'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'
import { IAuth } from 'egi/types'
import { useAuth } from 'hooks'
import React, { useEffect, useState } from 'react'
import translate from 'utils/translate'
import GenericChatUserModal, { IUserChat } from '../GenericChatUserModal/GenericChatUserModal'

interface IChatHeaderAreaNameProps {
  userId: string
}

const userAreaNameFactory = (user: IAuth) => {
  return {
    ...user,
    areaName: (user.areaName || user.type)
  }
}

function ChatHeaderAreaName ({ userId }: IChatHeaderAreaNameProps) {
  const [user, setUser] = useState<IAuth>()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const getUserDetails = async (id: string) => {
      try {
        setLoading(true)
        const response = await UsersEgiRepository.getDetails(id)
        const { userDetails } = response.data?.data || {}
        setLoading(false)
        setUser(userAreaNameFactory(userDetails) as IAuth)
        setErrorMessage('')
      } catch (err) {
        setErrorMessage(err?.message)
        setLoading(false)
      }
    }

    if (userId) getUserDetails(userId)
  }, [
    userId
  ])

  if (loading === true) {
    return <Skeleton.Avatar size='small' active className="chat-header__input-skeleton" />
  }

  if (errorMessage) {
    return <span className='chat-header__error-message'>{errorMessage}</span>
  }

  if (!user?.level && !user?.areaName) {
    return null
  }

  if (user?.level && !user.areaName) {
    return <span>{translate.level(user?.level)}</span>
  }

  return (
    <>
      <span>{translate.level(user?.level)}</span>
      <span>&nbsp;&#40;{translate.areasNames(user?.areaName)}&#41;</span>
    </>
  )
}

interface IProps {
  currentChat: IList | undefined
  onClick: () => void
  setTab: (value: 'list' | 'messages') => void
}

function ChatHeader ({ currentChat, onClick, setTab }: IProps) {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<IUserChat>()

  const getUserChatInformations = async (id: string) => {
    try {
      setLoading(true)
      const response = await ChatRepository.getUser(id)
      setUser(response.data.data.userDetails)
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const auth = useAuth()

  const onCancel = () => {
    setVisible(false)
  }

  const onOpenUserModal = () => {
    if (currentChat?._id && auth.helpdesk) {
      getUserChatInformations(currentChat?._id)
      setVisible(true)
    }
  }

  return (
    <div className='generic-chat__header'>
      <GenericChatUserModal
        visible={visible}
        chatUser={user}
        onCancel={onCancel}
        loading={loading}
        name={currentChat?.creator?.username}
      />

      <ArrowLeftOutlined
        className="px-2 generic-chat__header-back-to-list"
        onClick={() => setTab('list')}
      />

      {currentChat?.creator?.image
        ? <img className="generic-chat__header-avatar" src={currentChat?.creator?.image} />
        : <LetterAvatar name={currentChat?.creator?.username} />
      }

      <div
        className="generic-chat__header-info"
        onClick={onOpenUserModal}
      >
        <b
          className={
            auth.helpdesk
              ? 'generic-chat__header-user--helpdesk'
              : 'generic-chat__header-user'
          }
        >
          {currentChat?.creator?.username}
        </b>

        {auth.helpdesk && currentChat?.creatorId && (
          <p>
            <ChatHeaderAreaName userId={currentChat?.creatorId} />
          </p>
        )}

        <span className='generic-chat__header-members'>
          {currentChat?.users?.length} membros
        </span>

      </div>

      {ChatModel.canCloseChat(currentChat?.status.current.state, auth.helpdesk) && (
        <>
          <Button
            type="primary"
            className="generic-chat__end-chat"
            onClick={onClick}
          >
            Encerrar chamado
          </Button>

          <DeleteOutlined
            onClick={onClick}
            className='generic-chat__end-chat-icon'
          />
        </>
      )}
    </div>
  )
}

export default ChatHeader
