export const EGI = {
  ACCESS_MANAGER_BACKOFFICE: {
    path: '/access-manager/backoffice',
    name: 'Pesquisar Usuários Internos'
  },
  ACCESS_MANAGER_CLIENT: {
    path: '/access-manager/client',
    name: 'Busca Propostas EGI por CPF'
  },
  PROPOSAL_LIST: {
    path: '/proposals/list',
    name: 'Propostas'
  },
  LEADS: {
    path: '/leads',
    name: 'Leads'
  },
  PRICING_USERS: {
    path: '/pricingUsers',
    name: 'Usuários'
  },
  JURIDICO_LEADERS: {
    path: '/juridico/leaders',
    name: 'Administradores'
  },
  JURIDICO_PROVIDERS: {
    path: '/juridico/providers',
    name: 'Prestadores'
  },
  JURIDICO_PROMOTERS: {
    path: '/juridico/promoters',
    name: 'Parceiros'
  },
  ENGENHARIA_LEADERS: {
    path: '/engenharia/leaders',
    name: 'Administradores'
  },
  ENGENHARIA_PROVIDERS: {
    path: '/engenharia/providers',
    name: 'Prestadores'
  },
  COMMERCIAL_LEADERS: {
    path: '/commercial/leaders',
    name: 'Administradores'
  },
  CREDITO_LEADERS: {
    path: '/credito/leaders',
    name: 'Administradores'
  },
  AUDITORS: {
    path: '/auditors',
    name: 'Auditores'
  },
  ALL_INVITES: {
    path: '/allInvites',
    name: 'Convites gerados'
  },
  ATTORNEYS: {
    path: '/attorneys',
    name: 'Procuradores'
  },
  COMISSION_USERS: {
    path: '/commissionsUsers',
    name: 'Usuários comissão'
  },
  COLLABORATORS: {
    path: '/promoters',
    name: 'Colaboradores'
  },

  PROPOSAL_LIST_REMIDEND_TWO: {
    path: '/proposals/list/reminded',
    name: 'Tombadas'
  },

  PROPOSAL_LIST_TWO: {
    path: '/proposals/list',
    name: 'Visão geral'
  },

  PROPOSAL_LIST_SEARCH: {
    path: '/proposals/search',
    name: 'Busca geral'
  },

  PROPOSAL_LIST_ALL: {
    path: '/proposals/all',
    name: 'Visão geral'
  },

  PROPOSAL_LIST_LINKED_TWO: {
    path: '/proposals/linked',
    name: 'Vinculadas'

  },

  PROPOSAL_LIST_UNLINKED_TWO: {
    path: '/proposals/unlinked',
    name: 'Não vinculadas'
  }
}
