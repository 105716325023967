import React, { MutableRefObject, useLayoutEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, Modal } from 'antd'
import { Selfie } from 'components'
import { ICreateDivulgation, IDivulgationErrors, IDivulgationValues } from '../../views/Divulgation/types'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import MlmRepository from 'egi/repositories/MlmRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function CreateDivulgation ({ setVisible, visible, fieldsValue, resetFieldValue, fetch, resetImage }: ICreateDivulgation) {
  const formRef: MutableRefObject<any> = useRef(null)
  const [errors, setErrors] = useState<IDivulgationErrors>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [file, setFile] = useState<Blob>()

  const isDivulgation = fieldsValue.name && fieldsValue.description

  const onSubmit = async (values: IDivulgationValues) => {
    const { name, description } = values
    const formData = new FormData()

    formData.append('name', name)
    formData.append('description', description)
    if (file?.type) formData.append('file', file)

    if (isDivulgation) formData.append('materialId', fieldsValue.materialId)

    setLoading(true)
    try {
      if (isDivulgation) {
        const response = await MlmRepository.updateMaterials(formData)
        swal.basic({ icon: 'success', text: response.data?.message, title: 'Sucesso!' })
        formRef.current.resetFields()
        fetch()
        resetFieldValue()
        setLoading(false)
        setVisible(false)
      } else {
        const response = await MlmRepository.sendMaterials(formData)
        swal.basic({ icon: 'success', text: response.data?.message, title: 'Sucesso!' })
        formRef.current.resetFields()
        fetch()
        setLoading(false)
        setVisible(false)
        resetFieldValue()
        setFile(undefined)
      }
    } catch (err) {
      if (err.data?.invalid) {
        let errors: IDivulgationErrors = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        setLoading(false)
        return
      }
      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setLoading(false)
    }
  }

  function resetModal () {
    if (formRef && formRef.current) {
      formRef.current.resetFields()
    }

    setFile(undefined)
    resetFieldValue()
    setVisible(false)
  }

  useLayoutEffect(() => {
    if (fieldsValue.name && fieldsValue.description && fieldsValue.image) {
      if (formRef && formRef.current) {
        formRef.current.setFieldsValue({ description: fieldsValue.description, name: fieldsValue.name })
      }
    }
  }, [fieldsValue.name, fieldsValue.description])

  return (
    <Modal
      title={isDivulgation ? 'Editar divulgação' : 'Criar nova divulgação'}
      visible={visible}
      footer={null}
      onCancel={resetModal}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        ref={formRef}
        initialValues={{
          name: '',
          description: ''
        }}
      >
        <div className="flex flex-justify-center mb-4">
          <Form.Item
            label={<label>Imagem:</label>}
            className="mb-0"
          >
            <Selfie resetImage={resetImage} getFile={setFile} defaultImage={fieldsValue.image} />
          </Form.Item>
        </div>

        <Col>
          <Form.Item
            name="name"
            label={<label>Nome da divulgação:</label>}
            help={errors.name && errors.name}
            validateStatus={errors.name && ('error')}
          >
            <Input placeholder="Digite o nome da divulgação " />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            name="description"
            label={<label>Descrição da divulgação:</label>}
            help={errors.description && errors.description}
            validateStatus={errors.description && ('error')}
          >
            <Input placeholder="Digite a descrição da divulgação"/>
          </Form.Item>
        </Col>
        <div className="flex flex-justify-center">
          <Button type="primary" className="button-send-divulgation" loading={loading} htmlType="submit" >
            Enviar
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateDivulgation
